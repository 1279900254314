<template>
  <div>
    <!-- <div id="loading_1" name="loading_1"  class=""></div> -->
    <meshForm @playChange="playChange" @inputPlay="inputPlay" @meshResultSelect="meshResultSelect" @getTargetOptions="getTargetOptions" @distortionChange="distortionChange" @inputEnter="inputEnter" @getTargetPosition="getTargetPosition" ref="meshform"></meshForm>
    <!-- <el-button @click="moduleChange">点击切换{{ i }}</el-button> -->
    <!-- <div class="box mB10 win">
      
      <div class="fs18 noFlex" style="width: 86px">仿真场</div>
      <el-select v-model="valueNum" placeholder="请选择" @change="getValue">
        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div> -->
    <div class="tem_container_10_class box justify">
      <div class="tem_l radus24" v-show="meshTypeId == 3">
        <div v-if="true" class="tem_l_img">
          <img src="../../../assets/images/sansheng/jiemian_png@2x.png" alt="" />
          <el-table v-if="tableData.length" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
            <el-table-column align="center" prop="id" label="节点" :show-overflow-tooltip="true"> </el-table-column>

            <el-table-column align="center" prop="score" label="正应力(Mpa)" :show-overflow-tooltip="true"> </el-table-column>
          </el-table>
        </div>
        <div class="noDataMain health-empty" v-show="!tableData.length">
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
      <div class="flex1 radus24 pos-rel">
        <div id="container_w_10" style="top: 0px; left: 0px; width: 100%; height: 100%; position: absolute" v-show="calcFlag"></div>
        <canvas id="lut_w_10" width="250" height="450" style="top: 0px; left: 10px; width: 250px; height: 450px; position: absolute"></canvas>
        <div id="gui_w_10" style="top: 35px; right: 5px; position: absolute"></div>
        <div id="helper_w_10" style="bottom: 50px; left: 10px; width: 128px; height: 128px; position: absolute"></div>
        <div class="info-res" v-show="nodeFlag != 3  && item.name">
          <span style="padding-right: 10px">{{ item.name }}</span>
          <span style="padding-right: 10px">{{ value }}</span>
          <span>{{ item.unit }}</span>
        </div>
        <draggable @start="onStart" @end="onEnd" @add="add2" animation="300"><div id="drag" class="reset-simulation-model" @click="resetIcon"></div></draggable>
      </div>
      <!-- <div class="load-text" v-if="active == 1" :class="{ 'text-pos': meshTypeId == 3 }">
        <div>
          <p>重庆三圣特大桥为主跨（80+3×150+80）m 预应力混凝土连续刚构桥，主梁采用单箱单室截面，</p>
          <p>顶板宽 12.0m，底板宽 6.5m，顶板悬臂长度 2.75m；箱梁跨中梁高 3.3m，根部梁高 9.3m，梁底变化曲线为 1.7 次抛物线。</p>
        </div>
        <div style="margin-top: 8px">
          <p>主梁采用挂篮悬臂对称浇筑施工，</p>
          <p>梁段 1~6#长度为 3.0m，7~12#长度为 3.5m，13~19#长度为 4.0m</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { showMesh } from "../jsm/threemain/showmesh"; // ./jsm/threemain/showmesh   tomcloudmap
// import { Guidat, ViewHelper, LUT_text, CMThree, appShowMesh, CMMidas } from "tomcloudmap";

// import { appShowMesh } from "../jsm2/three/appShowMesh";
// import { CMThree } from "../jsm2/cm/cmthree";
// import { CMMidas } from "../jsm2/cm/cmMidas";
// import { LUT_text } from "../jsm2/LUT/LUT_text";
// import { ViewHelper } from "../jsm2/helper/Viewport.ViewHelper";
// import { Guidat } from "../jsm2/helper/guidathelper";
import { appShowMesh,CMThree,CMMidas,LUT_text,ViewHelper,Guidat} from "tomcloudmap";

import meshForm from "./common/meshForm";
import { typeOptionsW } from "./common/mesh";
import { scien } from "./scientifec";
import draggable from "vuedraggable";

export default {
  name: "meshshow",

  components: { meshForm, draggable },
  data() {
    return {
      tableData: [],
      i: 1,
      valueNum: "U.U",
      // 'U.U', 'U.V', 'U.W', 'U.M', 'S.S11', 'S.S22', 'S.S33', 'S.S23', 'S.S13', 'S.S12'
      list: [],
      meshFlag: true,
      meshTypeId: "",
      active: "1",
      calcFlag: true,
      item: {},
      node: {},
      value: "",
      nodeFlag: "",
      drag: { start: 0, end: 0 }
    };
  },
  computed: {},
  created() {
    // this.$bus.$on("caluResult", this.caluResult);
  },
  mounted() {
    // this.i = this.$route.query.i || 1;
    // console.log("mounted--i", this.i);
    // this.moduleVisbile(`./zip/${this.i}.zip`);
    this.active = this.$route.query.active || 1;
    console.log("active", this.active);
    document.getElementById("container_w_10").innerHTML = "";
    document.getElementById("helper_w_10").innerHTML = "";
    if (window.mainW) {
      window.mainW.clean();
      window.mainW = null;
    }
    this.$nextTick(() => {
      // this.moduleVisbile();
    });

    // this.moduleVisbileNew();
  },
  watch: {},
  methods: {
    resetIcon() {
      console.log("click");
      window.mainW.setENVLast();
    },
    add2(e) {
      console.log("eeee-adddd", e);
    },
    onStart(e) {
      if (this.drag.start == 0 && this.drag.end == 0) {
        this.drag = { start: e.originalEvent.clientX, end: e.originalEvent.clientY };
      }
    },
    onEnd(e) {
      let start = e.originalEvent.clientX;
      let end = e.originalEvent.clientY;
      let oDiv = document.getElementById("drag");
      oDiv.style.right = this.drag.start - start + 20  + "px";
      oDiv.style.bottom = this.drag.end - end + 20  + "px";
    },
    playChange(play, frameFrequency) {
      if (play) {
        window.mainW.CM.play(frameFrequency);
      } else {
        let arr = window.mainW.CMs[0].getCurrentCMLevel();

        let len = arr[0].length;
        for (let i = 0; i < len; i++) {
          if (arr[0][i] == arr[1]) {
            // this.searchForm.currentCMLevel = i + 1;
            this.$refs.meshform.currentCMLevel = i + 1;
            break;
          }
        }

        window.mainW.CM.stop();
      }
      window.mainW.CM.show();
    },

    inputPlay(frameFrequency) {
      window.mainW.CM.stop();

      window.mainW.CM.play(frameFrequency);
      window.mainW.CM.show();
    },

    // 仿真节点id
    getTargetPosition(item1) {
      console.log("反正节点", item1);
      this.node = item1;
      mainW.CMs[0].cleanInfoPoints();

      mainW.CMs[0].cleanSelectPoint();
      console.log("反正节点111111", item1);
      mainW.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      console.log("反正节点22221", item1);
      mainW.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      let info = mainW.CMs[0].getOnePointAllPressure(item1.name);
      console.log("获取仿真节点返回数据", info);
      let reg = {};
      if (this.item.fid == 1 || this.item.fid == 3) {
        reg = info.STR;
      } else {
        reg = info.CM;
      }
      this.setNodeValue(item1, reg);
      // if (this.item.fid == 1) {
      //   if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
      //     this.value = res[this.item.text][0];
      //   } else {
      //     this.value = res[this.item.text][1];
      //   }
      // }
      // if (this.item.fid == 2) {
      //   let res1 = mainW.CMs[0].getOnePointAllCM(item1.name);
      //   console.log("获取仿真节点返回数据2222", res1);
      //   this.value = res1[this.item.id];
      // }

      // this.getStressDAta(item1.id, JSON.stringify(res));
    },
    setNodeValue(item1, res) {
      let val;
      if (this.item.fid == 1) {
        if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
          val = res[this.item.text][0];
        } else {
          val = res[this.item.text][1];
        }
      }
      if (this.item.fid == 2) {
        // res = mainW.CMs[0].getOnePointAllCM(this.item.id);
        val = res[this.item.id];
      }
      this.value = scien(Number(val).toExponential());

      // this.getStressDAta(item1.id, JSON.stringify(res));  应力数据去掉2023.11.13
    },
    // 变形系数
    inputEnter(distortionFactor) {
      mainW.CMs[0].setTransRate(distortionFactor);
      mainW.CMs[0].show();
    },
    // 是否变形
    distortionChange(check, distortionFactor) {
      //  参数 1、是否变形 2、boolean 变形系数
      mainW.CMs[0].setTransEnable(check);
      console.log("变形");
      if (check) {
        mainW.CMs[0].setTransRate(distortionFactor);
      }

      mainW.CMs[0].show();
    },
    caluResultLoading(){
      document.getElementById("container_w_10").innerHTML = "";
      document.getElementById("helper_w_10").innerHTML = "";
      if (window.mainW) {
        window.mainW.clean();
        window.mainW = null;
      }

      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });



    },
    caluResultLoadingClose(){
      this.loadingPage.close();
    },
    caluResult(res) {
      console.log("计算结果返回模型数据", res);
      this.$refs["meshform"].formData.section = false;
      document.getElementById("container_w_10").innerHTML = "";
      document.getElementById("helper_w_10").innerHTML = "";
      if (window.mainW) {
        window.mainW.clean();
        window.mainW = null;
      }
      // let odiv = document.getElementById("container_w_10");
      // console.log("odiv.childNodes(0)", odiv.childNodes);
      // odiv.removeChild(odiv.childNodes[0]);
      // this.calcFlag = false;
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.calcFlag = true;
      //     this.moduleVisbile(res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
      //   }, 1000);
      // });
      this.$nextTick(() => {
        // this.calcFlag = true;
        // this.moduleVisbile(/*"./cm/sanshengbridge.zip"*/ res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
        this.midasVisible(res.data.url);
        // this.moduleVisbileNew(res.data.url);
      });
    },
    getTargetOptions(id) {
      console.log("qiehuan555", id, mainW);
      this.meshTypeId = id; // 切换仿真类型，界面变化
      if (this.meshTypeId == 3) {
        window.mainW.three.viewpoint_px = 506;
      } else {
        window.mainW.three.viewpoint_px = 62;
      }
      // window.mainW.CMs[0].show();
    },
    getMeshItem(id) {
      let n = typeOptionsW.length;
      let arr = typeOptionsW;
      // while(true){
      //   let arr1=arr
      //   for(let i=0;i<arr1.length;i++){
      //     if(arr1[i].id===id){
      //       return arr1[i]
      //     }else{
      //       if(arr1[i].data && arr1[i].data.length){
      //         arr=arr1
      //       }
      //     }

      //   }
      // }
    },
    // 云图交互-仿真结果
    meshResultSelect(id, typeid) {
      if (this.meshFlag) return;

      console.log("meshResultSelect-id", this.meshFlag, id, typeid, mainW.CMs[1], mainW.CMs[1].updateColors);
      this.nodeFlag = typeid;
      // let myid = typeOptions.filter((v) => v.id == id);
      if (typeid != 3) {
        let item = {};
        let n = typeOptionsW.length;
        for (let i = 0; i < n; i++) {
          if (typeOptionsW[i].id === typeid && typeOptionsW[i].data && typeOptionsW[i].data.length) {
            let k = typeOptionsW[i].data.length;
            for (let j = 0; j < k; j++) {
              console.log("云图交互", typeOptionsW[i].data[j].id);
              if (typeOptionsW[i].data[j].id === id) {
                this.item = typeOptionsW[i].data[j];
                this.item.fid = typeid;
                break;
              }
            }
          }
        }

        if (JSON.stringify(this.node) !== "{}") {
          this.getTargetPosition(this.node);
        }
      }

      setTimeout(() => {
        mainW.CMs[0].updateColors(id);

        window.mainW.CMs[0].show();
      }, 50);

      // switch (id) {
      //   case "2-1":
      //     mainW.CMs[0].updateColors("unoda0.u");
      //     break;
      //   case "2-2":
      //     mainW.CMs[0].updateColors("unoda0.v");
      //     break;
      //   case "2-3":
      //     mainW.CMs[0].updateColors("unoda0.w");
      //     break;
      //   case "2-4":
      //     mainW.CMs[0].updateColors("unoda1.anx");
      //     break;
      //   case "2-5":
      //     mainW.CMs[0].updateColors("unoda1.any");
      //     break;
      //   case "2-6":
      //     mainW.CMs[0].updateColors("unoda1.anz");
      //     break;
      // }
    },

    // 获取应力数据
    getStressDAta(pointsId, value) {
      let params = {
        pointsId, // 仿真节点id
        value // 应力
      };
      console.log("应力数据参数", params);
      // this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}simulation/calculate`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取应力5个点数据", res);

          let arr = [];
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              arr.push({ id: i + 1, score: res.data[i] });
            }
            this.tableData = arr;
          } else {
            this.tableData = [];
          }
        } else {
          // this.$message.error(res.errMsg);
          console.log("这里为什么报错呢", res);
        }
      });
    },

    getValue() {
      console.log("云图", this.valueNum);
      this.main.CM.show(this.valueNum);
      this.main.render();
    },
    getPointInfoAttr(id, info) {
      // mainT.CMs[0].cleanSelectPoint();
      mainW.CMs[0].cleanInfoPoints();
      this.$refs["meshform"].formData.positionGroupId = "";
      this.$refs["meshform"].formData.positionId = "";
      this.$refs["meshform"].pavement = "";
      console.log("模型传出值id", id, "info", info, "item", this.item);
      this.tableData = [];

      mainW.CMs[0].cleanInfoPoints();
      mainW.CMs[0].cleanSelectPoint();
      mainW.CMs[0].showInfoPoints([id], 0x888888, 3);

      let reg = {};
      if (this.item.fid == 1 || this.item.fid == 3) {
        reg = info.STR;
      } else {
        reg = info.CM;
      }
      this.setNodeValue({ id: id }, reg);
    },
    midasVisible(url = "") {
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });

      let that = this;
      let thisOfVUEentry = this;
      // clean DIV children ,on VDOM reload
      let cleanDIV = function (id) {
        let container = document.getElementById(id);
        container.innerHTML = "";
        // container.childNodes.forEach(function (item) {
        //     item.remove();
        // });
      };

      cleanDIV("container_w_10");
      cleanDIV("lut_w_10");
      cleanDIV("helper_w_10");
      cleanDIV("gui_w_10");

      // clean webGL canvas  ,on VDOM reload
      let cleanWebGL = function (object) {
        object.clean();
        object = {};
      };
      // log router
      let logRouter = function (msg) {
        console.log("Log Router :", msg);
        thisOfVUEentry.loadingPage.close();
      };

      let meshCM;
      let output = function (x, y, id, info) {
        console.log(x, y, id, info);
        thisOfVUEentry.getPointInfoAttr(id, info);
      };
      ///////////////////////////////////////////////////////////////////////////////////
      // value of init webGL
      let value = {
        DIV: "container_w_10",
        containerType: "div", //div or object
        ctl: "orbit", // orbit or traceball
        log: logRouter, //all log
        setting: {
          cameraAuto: false, // ture or false ,if the value is true or undefined ,below settings will be disable
          cameraPosition: [0, -450, 1], //[10, 0, 0],//x,y,z
          cameraMartix: false, //false or 4x4 martix ,if there has a array of matrix ,position will be igione
          cameraLookat: [], //x,y,z
          ctlTarget: [0, -1, 0], //x,y,z
          ////////////////////////////////////
          realTimeRender: true //
        },

        fun: {
          // beforeInit,init,afterinit of webGL's
          afterInit: function (that) {},
          init: function (that) {},
          onMouseClick: function (mainGL, raycaster, xy) {
            // console.log(raycaster, xy);
            if (mainGL.octree) {
              let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
              // mainGL.log("point id :", index);
              if (index) output(xy.eventxy.x, xy.eventxy.y, index, meshCM.getOnePointAllPressure(index));
            }
          }
          // onMouseMove: function (mainGL, raycaster, xy) {
          //     console.log(raycaster, xy);
          //     if (mainGL.octree) {
          //         let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
          //         mainGL.log("point id :", index);
          //     }
          // },
        },
        debug: true
      };

      // init webGL
      this.mainWebGL = new appShowMesh(value);
      ///////////////////////////////////////////////////////////////////////////////////
      //init LUT
      // must be init before CM init
      let inputValueLUT = {
        DIV: "lut_w_10",
        containerType: "div", //div or object
        kind: "curve", // curve ,default
        fontColor: { r: 1, g: 1.0, b: 1 },
        parent: this.mainWebGL
      };
      this.mainWebGL.LUT = new LUT_text(inputValueLUT);

      ///////////////////////////////////////////////////////////////////////////////////
      //init viewHelper
      let inputValueViewHelper = {
        DIV: "helper_w_10",
        containerType: "div", //div or object
        perpCamera: this.mainWebGL.perpCamera,
        parent: this.mainWebGL,
        up: "Y"
      };

      this.mainWebGL.viewHelper = new ViewHelper(inputValueViewHelper);

      ///////////////////////////////////////////////////////////////////////////////////
      // value of CM mesh
      let meshMidas = {};
      let inputForCM = {
        parent: this.mainWebGL,
        file: url || "./cm/sanshengbridge.zip",
        check: 2,
        cache: false,
        // extra:{
        //     midas:{
        //         file:"./str/625.mct",
        //         fun:{
        //             replaceNode:function(){},
        //             replaceElement:function(){},
        //         }
        //     },
        // },
        // rotate:{x:-90},//{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            meshMidas.info.data.res = CM.info.data.res;
            meshMidas.info.data.str = CM.info.data.str;
            ///////////////////////////////////////////////////////////////////////////////////
            //demo : GUI init
            let list1 = CM.info.getSiteListOfStr();
            let list = CM.getNameListOfCM().concat();
            if (list1) list = list.concat(list1);
            // let inputValueGUI = {
            //   DIV: "gui_w_10",
            //   containerType: "div", //div or object
            //   CM: CM,
            //   parent: CM.parent,
            //   listCM: list
            //   // CM.getNameListOfCM(),
            // };
            // CM.parent.gui = new Guidat(inputValueGUI);
            CM.Draw.drawShellPointsMerge("all");
            CM.initPickUp(CM, 3, 0x888888);

            window.mainW.three.viewpoint_py = 387;
            window.mainW.three.viewpoint_px = 62;

            console.log("云图加载完成", thisOfVUEentry.$refs["meshform"]);
            thisOfVUEentry.meshFlag = false;
            thisOfVUEentry.$nextTick(() => {
              console.log("云图加载完成=====", thisOfVUEentry, thisOfVUEentry.$refs["meshform"]);
              if (window.mainW) {
                window.mainW.setENVLast();
              }
              thisOfVUEentry.$refs["meshform"].formData.checked = false;
              thisOfVUEentry.$refs["meshform"].formData.section = true;
              thisOfVUEentry.$refs["meshform"].getTargetOptions("1", false, window.mainW);
              thisOfVUEentry.$refs["meshform"].getSection();
            });
          }
        },
        CM: {
          // type:"gid/inp",
          region: true //true/false
        },

        center: true, //true,false
        process: {
          add: {},
          edit: [
            {
              type: "point",
              nodes: [],
              value: [{}]
            },
            {
              type: "generate",
              nodes: [],
              value: [{}]
            }
          ],
          del: {}
        }
      };

      //init Mesh
      meshCM = new CMThree(inputForCM);
      this.mainWebGL.CMs.push(meshCM);

      ///////////////////////////////////////////////////////////////////////////////////
      // value of midas mesh
      let elemJSON = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
        25: "25",
        26: "26",
        27: "27",
        28: "28",
        29: "29",
        30: "30",
        31: "31",
        32: "32",
        33: "33",
        34: "34",
        35: "35",
        36: "36",
        37: "37",
        38: "38",
        39: "39",
        40: "40",
        41: "41",
        42: "42",
        43: "43",
        44: "44",
        45: "45",
        46: "46",
        47: "47",
        48: "48",
        49: "49",
        50: "50",
        51: "51",
        52: "52",
        53: "53",
        54: "54",
        55: "55",
        56: "56",
        57: "57",
        58: "58",
        59: "59",
        60: "60",
        61: "61",
        62: "62",
        63: "63",
        64: "64",
        65: "65",
        66: "66",
        67: "67",
        68: "68",
        69: "69",
        70: "70",
        71: "71",
        72: "72",
        73: "73",
        74: "74",
        75: "75",
        76: "76",
        77: "77",
        78: "78",
        79: "79",
        80: "80",
        81: "81",
        82: "82",
        83: "83",
        84: "84",
        85: "85",
        86: "86",
        87: "87",
        88: "88",
        89: "89",
        90: "90",
        91: "91",
        92: "92",
        93: "93",
        94: "94",
        95: "95",
        96: "96",
        97: "97",
        98: "98",
        99: "99",
        100: "100",
        101: "101",
        102: "102",
        103: "103",
        104: "104",
        105: "105",
        106: "106",
        107: "107",
        108: "108",
        109: "109",
        110: "110",
        111: "111",
        112: "112",
        113: "113",
        114: "114",
        115: "115",
        116: "116",
        117: "117",
        118: "118",
        119: "119",
        120: "120",
        121: "121",
        122: "122",
        123: "123",
        124: "124",
        125: "125",
        126: "126",
        127: "127",
        128: "128",
        129: "129",
        130: "130",
        131: "131",
        132: "132",
        133: "133",
        134: "134",
        135: "135",
        136: "136",
        137: "137",
        138: "138",
        139: "139",
        140: "140",
        141: "141",
        142: "142",
        143: "143",
        144: "144",
        145: "145",
        146: "146",
        147: "147",
        148: "148",
        149: "149",
        150: "150",
        151: "151",
        152: "152",
        153: "153",
        154: "154",
        155: "155",
        156: "156",
        157: "157",
        158: "158",
        159: "159",
        160: "160",
        161: "161",
        162: "162",
        163: "163",
        164: "164",
        165: "165",
        166: "166",
        167: "167",
        168: "168",
        169: "169",
        170: "170",
        171: "171",
        172: "172",
        173: "173",
        174: "174",
        175: "175",
        176: "176",
        177: "177",
        178: "178",
        179: "179",
        180: "180",
        181: "181",
        182: "182",
        183: "183",
        184: "184",
        185: "185",
        186: "186",
        187: "187",
        188: "188",
        189: "189",
        190: "190",
        191: "191",
        192: "192",
        193: "193",
        194: "194",
        195: "195",
        196: "196",
        197: "197",
        198: "198",
        199: "199",
        200: "200",
        201: "201",
        202: "202",
        203: "203",
        204: "204",
        205: "205",
        206: "206",
        207: "207",
        208: "208",
        209: "209",
        210: "210",
        211: "211",
        212: "212",
        213: "213",
        214: "214",
        215: "215",
        216: "216",
        217: "217",
        218: "218",
        219: "219",
        220: "220",
        221: "221",
        222: "222",
        223: "223",
        224: "224",
        225: "225",
        226: "226",
        227: "227",
        228: "228",
        229: "229",
        230: "230",
        231: "231",
        232: "232",
        233: "233",
        234: "234",
        235: "235",
        236: "236",
        237: "237",
        238: "238",
        239: "239",
        240: "240",
        241: "241",
        242: "242",
        243: "243",
        244: "244",
        245: "245",
        246: "246",
        247: "247",
        248: "248",
        249: "249",
        250: "250",
        251: "251",
        252: "252",
        253: "253",
        254: "254",
        255: "255",
        256: "256",
        257: "257",
        258: "258",
        259: "259",
        260: "260",
        261: "261",
        262: "262",
        263: "263",
        264: "264",
        265: "265",
        266: "266",
        267: "267",
        268: "268",
        269: "269",
        270: "270",
        271: "271",
        272: "272",
        273: "273",
        274: "274",
        275: "275",
        276: "276",
        277: "277",
        278: "278",
        279: "279",
        280: "280",
        281: "281",
        282: "282",
        283: "283",
        284: "284",
        285: "285",
        286: "286",
        287: "287",
        288: "288",
        289: "289",
        290: "290",
        291: "291",
        292: "292",
        293: "293",
        294: "294",
        295: "295",
        296: "296",
        297: "297",
        298: "298",
        299: "299",
        300: "300",
        301: "301",
        302: "302",
        303: "303",
        304: "304",
        305: "305",
        306: "306",
        307: "307",
        308: "308",
        309: "309",
        310: "310",
        311: "311",
        312: "312",
        313: "313",
        314: "314",
        315: "315",
        316: "316",
        317: "317",
        318: "318",
        319: "319",
        320: "320",
        321: "321",
        322: "322",
        323: "323",
        324: "324",
        325: "325",
        326: "326",
        327: "327",
        328: "328",
        329: "329",
        330: "330",
        331: "331",
        332: "332",
        333: "333",
        334: "334",
        335: "335",
        336: "336",
        337: "337",
        338: "338",
        339: "339",
        340: "340",
        341: "341",
        342: "342",
        343: "343",
        344: "344",
        345: "345",
        346: "346",
        347: "347",
        348: "348",
        349: "349",
        350: "350",
        351: "351",
        352: "352",
        353: "353",
        354: "354",
        355: "355",
        356: "356",
        357: "357",
        358: "358",
        359: "359",
        360: "360",
        361: "361",
        362: "362",
        363: "363",
        364: "364",
        365: "365",
        366: "366",
        367: "367",
        368: "368",
        369: "369",
        370: "370",
        371: "371",
        372: "372",
        373: "373",
        374: "374",
        375: "375",
        376: "376",
        377: "377",
        378: "378",
        379: "379",
        380: "380",
        381: "381",
        382: "382",
        383: "383",
        384: "384",
        385: "385",
        386: "386",
        387: "387",
        388: "388",
        389: "389",
        390: "390",
        391: "391",
        392: "392",
        393: "393",
        394: "394",
        395: "395",
        396: "396",
        397: "397",
        398: "398",
        399: "399",
        400: "400",
        401: "401",
        402: "402",
        403: "403",
        404: "404",
        405: "405",
        406: "406",
        407: "407",
        408: "408",
        409: "409",
        410: "410",
        411: "411",
        412: "412",
        413: "413",
        414: "414",
        415: "415",
        416: "416",
        417: "417",
        418: "418",
        419: "419",
        420: "420",
        421: "421",
        422: "422",
        423: "423",
        424: "424",
        425: "425",
        426: "426",
        427: "427",
        428: "428",
        429: "429",
        430: "430",
        431: "431",
        432: "432",
        433: "433",
        434: "434",
        435: "435",
        436: "436",
        437: "437",
        438: "438",
        439: "439",
        440: "440",
        441: "441",
        442: "442",
        443: "443",
        444: "444",
        445: "445",
        446: "446",
        451: "447",
        452: "448",
        453: "449",
        454: "450",
        455: "451",
        456: "452",
        457: "453",
        458: "454",
        459: "455",
        460: "456",
        461: "457",
        462: "458",
        463: "459",
        464: "460",
        465: "461",
        466: "462",
        467: "463",
        468: "464",
        469: "465",
        470: "466",
        471: "467",
        472: "468",
        473: "469",
        474: "470",
        475: "471",
        476: "472",
        477: "473",
        478: "474",
        479: "475",
        480: "476",
        481: "477",
        482: "478",
        483: "479",
        484: "480",
        485: "481",
        486: "482",
        487: "483",
        488: "484",
        489: "485",
        490: "486",
        491: "487",
        492: "488",
        493: "489",
        494: "490",
        495: "491",
        496: "492",
        497: "493",
        498: "494",
        499: "495",
        500: "496",
        501: "497",
        502: "498",
        503: "499",
        504: "500",
        505: "501",
        506: "502",
        507: "503",
        508: "504",
        509: "505",
        510: "506",
        511: "507",
        512: "508",
        513: "509",
        514: "510",
        515: "511",
        516: "512",
        517: "513",
        518: "514",
        519: "515",
        520: "516",
        521: "517",
        522: "518",
        523: "519",
        524: "520",
        525: "521",
        526: "522",
        527: "523",
        528: "524",
        529: "525",
        530: "526",
        531: "527",
        532: "528",
        533: "529",
        534: "530",
        535: "531",
        536: "532",
        537: "533",
        538: "534",
        539: "535",
        540: "536",
        541: "537",
        542: "538",
        543: "539",
        544: "540",
        545: "541",
        546: "542",
        547: "543",
        548: "544",
        549: "545",
        550: "546",
        551: "547",
        552: "548",
        553: "549",
        554: "550",
        555: "551",
        556: "552",
        557: "553",
        558: "554",
        559: "555",
        560: "556",
        561: "557",
        562: "558",
        563: "559",
        564: "560",
        565: "561",
        566: "562",
        567: "563",
        568: "564",
        569: "565",
        570: "566",
        571: "567",
        572: "568",
        573: "569",
        574: "570",
        575: "571",
        576: "572",
        577: "573",
        578: "574",
        579: "575",
        580: "576",
        581: "577",
        582: "578",
        583: "579",
        584: "580",
        585: "581",
        586: "582",
        587: "583",
        588: "584",
        589: "585",
        590: "586",
        591: "587",
        592: "588",
        593: "589",
        594: "590",
        595: "591",
        596: "592",
        597: "593",
        598: "594",
        599: "595",
        600: "596",
        601: "597",
        602: "598",
        603: "599",
        604: "600",
        605: "601",
        606: "602",
        607: "603",
        608: "604",
        609: "605",
        610: "606",
        611: "607",
        612: "608",
        613: "609",
        614: "610",
        615: "611",
        616: "612",
        617: "613",
        618: "614",
        619: "615",
        620: "616",
        621: "617",
        622: "618",
        623: "619",
        624: "620",
        625: "621",
        626: "622",
        627: "623",
        628: "624",
        629: "625",
        630: "626",
        631: "627",
        632: "628",
        633: "629",
        634: "630",
        635: "631",
        636: "632",
        637: "633",
        638: "634",
        639: "635",
        640: "636",
        641: "637",
        642: "638",
        643: "639",
        644: "640",
        645: "641",
        646: "642",
        647: "643",
        648: "644",
        649: "645",
        650: "646",
        651: "647",
        652: "648",
        653: "649",
        654: "650",
        655: "651",
        656: "652",
        657: "653",
        658: "654",
        659: "655",
        660: "656",
        661: "657",
        662: "658",
        663: "659",
        664: "660",
        665: "661",
        666: "662",
        667: "663",
        668: "664",
        669: "665",
        670: "666",
        671: "667",
        672: "668",
        673: "669",
        674: "670",
        675: "671",
        676: "672",
        677: "673",
        678: "674",
        679: "675",
        680: "676",
        681: "677",
        682: "678",
        683: "679",
        684: "680",
        685: "681",
        686: "682",
        687: "683",
        688: "684",
        689: "685",
        690: "686",
        691: "687",
        692: "688",
        693: "689",
        694: "690",
        695: "691",
        696: "692",
        697: "693",
        698: "694",
        699: "695",
        700: "696",
        701: "697",
        702: "698",
        703: "699",
        704: "700",
        705: "701",
        706: "702",
        707: "703",
        708: "704",
        709: "705",
        710: "706",
        711: "707",
        712: "708",
        713: "709",
        714: "710",
        715: "711",
        716: "712",
        717: "713",
        718: "714",
        719: "715",
        720: "716",
        721: "717",
        722: "718",
        723: "719",
        724: "720",
        725: "721",
        726: "722",
        727: "723",
        728: "724",
        729: "725",
        730: "726",
        731: "727",
        732: "728",
        733: "729",
        734: "730",
        735: "731",
        736: "732",
        737: "733",
        738: "734",
        739: "735",
        740: "736",
        741: "737",
        742: "738",
        743: "739",
        744: "740",
        745: "741",
        746: "742",
        747: "743",
        748: "744",
        749: "745",
        750: "746",
        751: "747",
        752: "748",
        753: "749",
        754: "750",
        755: "751",
        756: "752",
        757: "753",
        758: "754",
        759: "755",
        760: "756",
        761: "757",
        762: "758",
        763: "759",
        764: "760",
        765: "761",
        766: "762",
        767: "763",
        768: "764",
        769: "765",
        770: "766",
        771: "767",
        772: "768",
        773: "769",
        774: "770",
        775: "771",
        776: "772",
        777: "773",
        778: "774",
        779: "775",
        780: "776",
        781: "777",
        782: "778",
        783: "779",
        784: "780",
        785: "781",
        786: "782",
        787: "783",
        788: "784",
        789: "785",
        790: "786",
        791: "787",
        792: "788",
        793: "789",
        794: "790",
        795: "791",
        796: "792",
        797: "793",
        798: "794",
        799: "795",
        800: "796",
        801: "797",
        802: "798",
        803: "799",
        804: "800",
        805: "801",
        806: "802",
        807: "803",
        808: "804",
        809: "805",
        810: "806",
        811: "807",
        812: "808",
        813: "809",
        814: "810",
        815: "811",
        816: "812",
        817: "813",
        818: "814",
        819: "815",
        820: "816",
        821: "817",
        822: "818",
        823: "819",
        824: "820",
        825: "821",
        826: "822",
        827: "823",
        828: "824",
        829: "825",
        830: "826",
        831: "827",
        832: "828",
        833: "829",
        834: "830",
        835: "831",
        836: "832",
        837: "833",
        838: "834",
        839: "835",
        840: "836",
        841: "837",
        842: "838",
        843: "839",
        844: "840",
        845: "841",
        846: "842",
        847: "843",
        848: "844",
        849: "845",
        850: "846",
        851: "847",
        852: "848",
        853: "849",
        854: "850",
        855: "851",
        856: "852",
        857: "853",
        858: "854",
        859: "855",
        860: "856",
        861: "857",
        862: "858",
        863: "859",
        864: "860",
        865: "861",
        866: "862",
        867: "863",
        868: "864",
        869: "865",
        870: "866",
        871: "867",
        872: "868",
        873: "869",
        874: "870",
        875: "871",
        876: "872",
        877: "873",
        878: "874",
        879: "875",
        880: "876",
        881: "877",
        882: "878",
        883: "879",
        884: "880",
        885: "881",
        886: "882",
        887: "883",
        888: "884",
        889: "885",
        890: "886",
        891: "887",
        892: "888",
        893: "889",
        894: "890",
        895: "891",
        896: "892",
        897: "893",
        898: "894",
        899: "895",
        900: "896",
        901: "897",
        902: "898",
        903: "899",
        904: "900",
        905: "901",
        906: "902",
        907: "903",
        908: "904",
        909: "905",
        910: "906",
        911: "907",
        912: "908",
        913: "909",
        914: "910",
        915: "911",
        916: "912",
        917: "913",
        918: "914",
        919: "915",
        920: "916",
        921: "917",
        922: "918",
        923: "919",
        924: "920",
        925: "921",
        926: "922",
        927: "923",
        928: "924",
        929: "925",
        930: "926",
        931: "927",
        932: "928",
        933: "929",
        934: "930",
        935: "931",
        936: "932",
        937: "933",
        938: "934",
        939: "935",
        940: "936",
        941: "937",
        942: "938",
        943: "939",
        944: "940",
        945: "941",
        946: "942",
        947: "943",
        948: "944",
        949: "945",
        950: "946",
        951: "947",
        952: "948",
        953: "949",
        954: "950",
        955: "951",
        956: "952",
        957: "953",
        958: "954",
        959: "955",
        960: "956",
        961: "957",
        962: "958",
        963: "959",
        964: "960",
        965: "961",
        966: "962",
        967: "963",
        968: "964",
        969: "965",
        970: "966",
        971: "967",
        972: "968",
        973: "969",
        974: "970",
        975: "971",
        976: "972",
        977: "973",
        978: "974",
        979: "975",
        980: "976",
        981: "977",
        982: "978",
        983: "979",
        984: "980",
        985: "981",
        986: "982",
        987: "983",
        988: "984",
        989: "985",
        990: "986",
        991: "987",
        992: "988",
        993: "989",
        994: "990",
        995: "991",
        996: "992",
        997: "993",
        998: "994",
        999: "995",
        1000: "996",
        1001: "997",
        1002: "998",
        1003: "999",
        1004: "1000",
        1005: "1001",
        1006: "1002",
        1007: "1003",
        1008: "1004",
        1009: "1005",
        1010: "1006",
        1011: "1007",
        1012: "1008",
        1013: "1009",
        1014: "1010",
        1015: "1011",
        1016: "1012",
        1017: "1013",
        1018: "1014",
        1019: "1015",
        1020: "1016"
      };
      let nodeJSON = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
        25: "25",
        26: "26",
        27: "27",
        28: "28",
        29: "29",
        30: "30",
        31: "31",
        32: "32",
        33: "33",
        34: "34",
        35: "35",
        36: "36",
        37: "37",
        38: "38",
        39: "39",
        40: "40",
        41: "41",
        42: "42",
        43: "43",
        44: "44",
        45: "45",
        46: "46",
        47: "47",
        48: "48",
        49: "49",
        50: "50",
        51: "51",
        52: "52",
        53: "53",
        54: "54",
        55: "55",
        56: "56",
        57: "57",
        58: "58",
        59: "59",
        60: "60",
        61: "61",
        62: "62",
        63: "63",
        64: "64",
        65: "65",
        66: "66",
        67: "67",
        68: "68",
        69: "69",
        70: "70",
        71: "71",
        72: "72",
        73: "73",
        74: "74",
        75: "75",
        76: "76",
        77: "77",
        78: "78",
        79: "79",
        80: "80",
        81: "81",
        82: "82",
        83: "83",
        84: "84",
        85: "85",
        86: "86",
        87: "87",
        88: "88",
        89: "89",
        90: "90",
        91: "91",
        92: "92",
        93: "93",
        94: "94",
        95: "95",
        96: "96",
        97: "97",
        98: "98",
        99: "99",
        100: "100",
        101: "101",
        102: "102",
        103: "103",
        104: "104",
        105: "105",
        106: "106",
        107: "107",
        108: "108",
        109: "109",
        110: "110",
        111: "111",
        112: "112",
        113: "113",
        114: "114",
        115: "115",
        116: "116",
        117: "117",
        118: "118",
        119: "119",
        120: "120",
        121: "121",
        122: "122",
        123: "123",
        124: "124",
        125: "125",
        126: "126",
        127: "127",
        128: "128",
        129: "129",
        130: "130",
        131: "131",
        132: "132",
        133: "133",
        134: "134",
        135: "135",
        136: "136",
        137: "137",
        138: "138",
        139: "139",
        140: "140",
        141: "141",
        142: "142",
        143: "143",
        144: "144",
        145: "145",
        146: "146",
        147: "147",
        148: "148",
        149: "149",
        150: "150",
        151: "151",
        152: "152",
        153: "153",
        154: "154",
        155: "155",
        156: "156",
        157: "157",
        158: "158",
        159: "159",
        160: "160",
        161: "161",
        162: "162",
        163: "163",
        164: "164",
        165: "165",
        166: "166",
        167: "167",
        168: "168",
        169: "169",
        170: "170",
        171: "171",
        172: "172",
        173: "173",
        174: "174",
        175: "175",
        176: "176",
        177: "177",
        178: "178",
        179: "179",
        180: "180",
        181: "181",
        182: "182",
        183: "183",
        184: "184",
        185: "185",
        186: "186",
        187: "187",
        188: "188",
        189: "189",
        190: "190",
        191: "191",
        192: "192",
        193: "193",
        194: "194",
        195: "195",
        196: "196",
        197: "197",
        198: "198",
        199: "200",
        200: "202",
        201: "204",
        202: "206",
        203: "208",
        204: "210",
        205: "214",
        206: "216",
        207: "217",
        208: "218",
        209: "219",
        210: "221",
        211: "222",
        212: "223",
        213: "224",
        214: "225",
        215: "226",
        216: "227",
        217: "228",
        218: "229",
        219: "230",
        220: "231",
        221: "232",
        222: "233",
        223: "234",
        224: "235",
        225: "236",
        226: "237",
        227: "238",
        228: "239",
        229: "240",
        230: "241",
        231: "242",
        232: "243",
        233: "244",
        234: "245",
        235: "246",
        236: "248",
        237: "249",
        238: "250",
        239: "251",
        240: "252",
        241: "253",
        242: "254",
        243: "255",
        244: "256",
        245: "257",
        246: "258",
        247: "259",
        248: "260",
        249: "261",
        250: "262",
        251: "263",
        252: "264",
        253: "265",
        254: "266",
        255: "267",
        256: "268",
        257: "269",
        258: "270",
        259: "271",
        260: "272",
        261: "273",
        262: "275",
        263: "276",
        264: "277",
        265: "278",
        266: "279",
        267: "280",
        268: "281",
        269: "282",
        270: "283",
        271: "284",
        272: "285",
        273: "286",
        274: "287",
        275: "288",
        276: "289",
        277: "290",
        278: "291",
        279: "292",
        280: "293",
        281: "294",
        282: "295",
        283: "296",
        284: "297",
        285: "298",
        286: "299",
        287: "300",
        288: "301",
        289: "302",
        290: "303",
        291: "304",
        292: "305",
        293: "306",
        294: "307",
        295: "308",
        296: "309",
        297: "310",
        298: "311",
        299: "312",
        300: "313",
        301: "314",
        302: "315",
        303: "316",
        304: "317",
        305: "318",
        306: "319",
        307: "320",
        308: "321",
        309: "322",
        310: "323",
        311: "324",
        312: "325",
        313: "326",
        314: "327",
        315: "328",
        316: "329",
        317: "330",
        318: "331",
        319: "332",
        320: "333",
        321: "334",
        322: "335",
        323: "336",
        324: "337",
        325: "338",
        326: "339",
        327: "340",
        328: "342",
        329: "343",
        330: "344",
        331: "345",
        332: "346",
        333: "347",
        334: "348",
        335: "349",
        336: "350",
        337: "351",
        338: "352",
        339: "353",
        340: "354",
        341: "355",
        342: "356",
        343: "357",
        344: "358",
        345: "359",
        346: "360",
        347: "361",
        348: "362",
        349: "363",
        350: "364",
        351: "365",
        352: "366",
        353: "367",
        354: "368",
        355: "369",
        356: "370",
        357: "371",
        358: "372",
        359: "373",
        360: "374",
        361: "375",
        362: "376",
        363: "377",
        364: "378",
        365: "379",
        366: "380",
        367: "381",
        368: "382",
        369: "383",
        370: "384",
        371: "385",
        372: "386",
        373: "387",
        374: "388",
        375: "389",
        376: "390",
        377: "391",
        378: "392",
        379: "393",
        380: "394",
        381: "395",
        382: "396",
        383: "397",
        384: "398",
        385: "399",
        386: "400",
        387: "401",
        388: "402",
        389: "403",
        390: "404",
        391: "405",
        392: "406",
        393: "407",
        394: "408",
        395: "409",
        396: "410",
        397: "411",
        398: "412",
        399: "413",
        400: "415",
        401: "416",
        402: "417",
        403: "418",
        404: "419",
        405: "420",
        406: "421",
        407: "422",
        408: "423",
        409: "424",
        410: "425",
        411: "426",
        412: "427",
        413: "428",
        414: "429",
        415: "430",
        416: "431",
        417: "432",
        418: "433",
        419: "434",
        420: "435",
        421: "436",
        422: "437",
        423: "438",
        424: "439",
        425: "440",
        426: "441",
        427: "442",
        428: "443",
        429: "444",
        430: "445",
        431: "446",
        432: "447",
        433: "448",
        434: "449",
        435: "450",
        436: "451",
        437: "452",
        438: "453",
        439: "454",
        440: "455",
        441: "456",
        442: "457",
        443: "458",
        444: "459",
        445: "460",
        446: "461",
        447: "462",
        448: "463",
        449: "464",
        450: "465",
        451: "466",
        452: "467",
        453: "468",
        454: "469",
        455: "470",
        456: "473",
        457: "474",
        458: "475",
        459: "476",
        460: "477",
        461: "478",
        462: "479",
        463: "480",
        464: "481",
        465: "482",
        466: "483",
        467: "484",
        468: "485",
        469: "486",
        470: "487",
        471: "488",
        472: "489",
        473: "490",
        474: "491",
        475: "492",
        476: "493",
        477: "494",
        478: "495",
        479: "496",
        480: "497",
        481: "498",
        482: "499",
        483: "500",
        484: "501",
        485: "502",
        486: "503",
        487: "504",
        488: "505",
        489: "506",
        490: "507",
        491: "508",
        492: "509",
        493: "510",
        494: "511",
        495: "512",
        496: "513",
        497: "514",
        498: "515",
        499: "516",
        500: "517",
        501: "518",
        502: "519",
        503: "520",
        504: "521",
        505: "522",
        506: "523",
        507: "524",
        508: "525",
        509: "526",
        510: "527",
        511: "528",
        512: "529",
        513: "530",
        514: "531",
        515: "532",
        516: "533",
        517: "534",
        518: "535",
        519: "536",
        520: "537",
        521: "538",
        522: "539",
        523: "540",
        524: "541",
        525: "542",
        526: "543",
        527: "544",
        528: "545",
        529: "546",
        530: "547",
        531: "548",
        532: "549",
        533: "550",
        534: "551",
        535: "552",
        536: "553",
        537: "554",
        538: "555",
        539: "556",
        540: "557",
        541: "558",
        542: "559",
        543: "560",
        544: "561",
        545: "562",
        546: "563",
        547: "564",
        548: "565",
        549: "566",
        550: "567",
        551: "568",
        552: "569",
        553: "570",
        554: "571",
        555: "572",
        556: "573",
        557: "574",
        558: "575",
        559: "576",
        560: "577",
        561: "578",
        562: "579",
        563: "580",
        564: "581",
        565: "582",
        566: "583",
        567: "584",
        568: "585",
        569: "586",
        570: "587",
        571: "588",
        572: "589",
        573: "590",
        574: "591",
        575: "592",
        576: "593",
        577: "594",
        578: "595",
        579: "596",
        580: "597",
        581: "598",
        582: "599",
        583: "600",
        584: "601",
        585: "602",
        586: "603",
        587: "604",
        588: "605",
        589: "606",
        590: "607",
        591: "608",
        592: "609",
        593: "610",
        594: "611",
        595: "612",
        596: "613",
        597: "614",
        598: "615",
        599: "616",
        600: "617",
        601: "618",
        602: "619",
        603: "620",
        604: "621",
        605: "622",
        606: "623",
        607: "624",
        608: "625",
        609: "626",
        610: "627",
        611: "628",
        612: "629",
        613: "630",
        614: "631",
        615: "632",
        616: "633",
        617: "634",
        618: "635",
        619: "636",
        620: "637",
        621: "638",
        622: "639",
        623: "640",
        624: "641",
        625: "642",
        626: "643",
        627: "644",
        628: "645",
        629: "646",
        630: "647",
        631: "648",
        632: "649",
        633: "650",
        634: "651",
        635: "652",
        636: "653",
        637: "654",
        638: "655",
        639: "656",
        640: "657",
        641: "658",
        642: "659",
        643: "660",
        644: "661",
        645: "662",
        646: "663",
        647: "664",
        648: "665",
        649: "666",
        650: "667",
        651: "668",
        652: "669",
        653: "670",
        654: "671",
        655: "672",
        656: "673",
        657: "674",
        658: "675",
        659: "676",
        660: "677",
        661: "678",
        662: "679",
        663: "680",
        664: "681",
        665: "682",
        666: "683",
        667: "684",
        668: "685",
        669: "686",
        670: "687",
        671: "688",
        672: "689",
        673: "690",
        674: "691",
        675: "692",
        676: "693",
        677: "694",
        678: "695",
        679: "696",
        680: "697",
        681: "698",
        682: "699",
        683: "700",
        684: "701",
        685: "702",
        686: "703",
        687: "704",
        688: "705",
        689: "706",
        690: "707",
        691: "708",
        692: "709",
        693: "710",
        694: "711",
        695: "712",
        696: "713",
        697: "714",
        698: "715",
        699: "716",
        700: "717",
        701: "718",
        702: "719",
        703: "720",
        704: "721",
        705: "722",
        706: "723",
        707: "724",
        708: "725",
        709: "726",
        710: "727",
        711: "728",
        712: "729",
        713: "730",
        714: "731",
        715: "732",
        716: "733",
        717: "734",
        718: "735",
        719: "736",
        720: "737",
        721: "738",
        722: "739",
        723: "740",
        724: "741",
        725: "742",
        726: "743",
        727: "744",
        728: "745",
        729: "746",
        730: "747",
        731: "748",
        732: "749",
        733: "750",
        734: "751",
        735: "752",
        736: "753",
        737: "754",
        738: "755",
        739: "756",
        740: "757",
        741: "758",
        742: "759",
        743: "760",
        744: "761",
        745: "762",
        746: "763",
        747: "764",
        748: "765",
        749: "766",
        750: "767",
        751: "768",
        752: "769",
        753: "770",
        754: "771",
        755: "772",
        756: "773",
        757: "774",
        758: "775",
        759: "776",
        760: "777",
        761: "778",
        762: "779",
        763: "780",
        764: "781",
        765: "782",
        766: "783",
        767: "784",
        768: "785",
        769: "786",
        770: "787",
        771: "788",
        772: "789",
        773: "790",
        774: "791",
        775: "792",
        776: "793",
        777: "794",
        778: "795",
        779: "796",
        780: "797",
        781: "798",
        782: "799",
        783: "800",
        784: "801",
        785: "802",
        786: "803",
        787: "804",
        788: "805",
        789: "806",
        790: "807",
        791: "808",
        792: "809",
        793: "810",
        794: "811",
        795: "812",
        796: "813",
        797: "814",
        798: "815",
        799: "816",
        800: "817",
        801: "818",
        802: "819",
        803: "820",
        804: "821",
        805: "822",
        806: "823",
        807: "824",
        808: "825",
        809: "826",
        810: "827",
        811: "828",
        812: "829",
        813: "830",
        814: "831",
        815: "832",
        816: "833",
        817: "834",
        818: "835",
        819: "836",
        820: "837",
        821: "838",
        822: "839",
        823: "840",
        824: "841",
        825: "842",
        826: "843",
        827: "844",
        828: "845",
        829: "846",
        830: "847",
        831: "848",
        832: "849",
        833: "850",
        834: "851",
        835: "852",
        836: "853",
        837: "854",
        838: "855",
        839: "856",
        840: "857",
        841: "858",
        842: "859",
        843: "860",
        844: "861",
        845: "862",
        846: "863",
        847: "864",
        848: "865",
        849: "866",
        850: "867",
        851: "868",
        852: "869",
        853: "870",
        854: "871",
        855: "872",
        856: "873",
        857: "874",
        858: "875",
        859: "876",
        860: "877",
        861: "878",
        862: "879",
        863: "880",
        864: "881",
        865: "882",
        866: "883",
        867: "884",
        868: "885",
        869: "886",
        870: "887",
        871: "888",
        872: "889",
        873: "890",
        874: "891",
        875: "892",
        876: "893",
        877: "894",
        878: "895",
        879: "896",
        880: "897",
        881: "898",
        882: "899",
        883: "900",
        884: "901",
        885: "902",
        886: "903",
        887: "904",
        888: "905",
        889: "906",
        890: "907",
        891: "908",
        892: "909",
        893: "910",
        894: "911",
        895: "912",
        896: "913",
        897: "914",
        898: "915",
        899: "916",
        900: "917",
        901: "918",
        902: "919",
        903: "920",
        904: "921",
        905: "922",
        906: "923",
        907: "924",
        908: "925",
        909: "926",
        910: "927",
        911: "928",
        912: "929",
        913: "930",
        914: "931",
        915: "932",
        916: "933",
        917: "934",
        918: "935",
        919: "936",
        920: "937",
        921: "938",
        922: "939",
        923: "940",
        924: "941",
        925: "942",
        926: "943",
        927: "944",
        928: "945",
        929: "946",
        930: "947",
        931: "948",
        932: "949",
        933: "950",
        934: "951",
        935: "952",
        936: "953",
        937: "954",
        938: "955",
        939: "956",
        940: "957",
        941: "958",
        942: "959",
        943: "960",
        944: "961",
        945: "962",
        946: "963",
        947: "964",
        948: "965",
        949: "966",
        950: "967",
        951: "968",
        952: "969",
        953: "970",
        954: "971",
        955: "972",
        956: "973",
        957: "974",
        958: "975",
        959: "976",
        960: "977",
        961: "978",
        962: "979",
        963: "980",
        964: "981",
        965: "982",
        966: "983",
        967: "984",
        968: "985",
        969: "986",
        970: "987",
        971: "988",
        972: "989",
        973: "990",
        974: "991",
        975: "992",
        976: "993",
        977: "994",
        978: "995",
        979: "996",
        980: "997",
        981: "998",
        982: "999",
        983: "1000",
        984: "1001",
        985: "1002",
        986: "1003",
        987: "1004",
        988: "1005",
        989: "1006",
        990: "1007",
        991: "1008",
        992: "1009",
        993: "1010",
        994: "1011",
        995: "1012",
        996: "1013",
        997: "1014",
        998: "1015",
        999: "1016",
        1000: "1017",
        1001: "1018",
        1002: "1019",
        1003: "1020",
        1004: "1021",
        1005: "1022",
        1006: "1023",
        1007: "1024",
        1008: "1025",
        1009: "1026",
        1010: "1027",
        1011: "1028",
        1012: "1029",
        1013: "1030",
        1014: "1031",
        1015: "1032",
        1016: "1033",
        1017: "1034",
        1018: "1035",
        1019: "1036",
        1020: "1037",
        1021: "1038",
        1022: "1039",
        1023: "1040",
        1024: "1041",
        1025: "1042",
        1026: "1043",
        1027: "1044",
        1028: "1045",
        1029: "1046",
        1030: "1047",
        1031: "1048",
        1032: "1049",
        1033: "1050",
        1034: "1051",
        1035: "1052",
        1036: "1053",
        1037: "1054",
        1038: "1055",
        1039: "1056",
        1040: "1057",
        1041: "1058",
        1042: "1059",
        1043: "1060",
        1044: "1061",
        1045: "1062",
        1046: "1063",
        1047: "1064",
        1048: "1065",
        1049: "1066",
        1050: "1067"
      };
      let nodeJSONOld = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
        25: "25",
        26: "26",
        27: "27",
        28: "28",
        29: "29",
        30: "30",
        31: "31",
        32: "32",
        33: "33",
        34: "34",
        35: "35",
        36: "36",
        37: "37",
        38: "38",
        39: "39",
        40: "40",
        41: "41",
        42: "42",
        43: "43",
        44: "44",
        45: "45",
        46: "46",
        47: "47",
        48: "48",
        49: "49",
        50: "50",
        51: "51",
        52: "52",
        53: "53",
        54: "54",
        55: "55",
        56: "56",
        57: "57",
        58: "58",
        59: "59",
        60: "60",
        61: "61",
        62: "62",
        63: "63",
        64: "64",
        65: "65",
        66: "66",
        67: "67",
        68: "68",
        69: "69",
        70: "70",
        71: "71",
        72: "72",
        73: "73",
        74: "74",
        75: "75",
        76: "76",
        77: "77",
        78: "78",
        79: "79",
        80: "80",
        81: "81",
        82: "82",
        83: "83",
        84: "84",
        85: "85",
        86: "86",
        87: "87",
        88: "88",
        89: "89",
        90: "90",
        91: "91",
        92: "92",
        93: "93",
        94: "94",
        95: "95",
        96: "96",
        97: "97",
        98: "98",
        99: "99",
        100: "100",
        101: "101",
        102: "102",
        103: "103",
        104: "104",
        105: "105",
        106: "106",
        107: "107",
        108: "108",
        109: "109",
        110: "110",
        111: "111",
        112: "112",
        113: "113",
        114: "114",
        115: "115",
        116: "116",
        117: "117",
        118: "118",
        119: "119",
        120: "120",
        121: "121",
        122: "122",
        123: "123",
        124: "124",
        125: "125",
        126: "126",
        127: "127",
        128: "128",
        129: "129",
        130: "130",
        131: "131",
        132: "132",
        133: "133",
        134: "134",
        135: "135",
        136: "136",
        137: "137",
        138: "138",
        139: "139",
        140: "140",
        141: "141",
        142: "142",
        143: "143",
        144: "144",
        145: "145",
        146: "146",
        147: "147",
        148: "148",
        149: "149",
        150: "150",
        151: "151",
        152: "152",
        153: "153",
        154: "154",
        155: "155",
        156: "156",
        157: "157",
        158: "158",
        159: "159",
        160: "160",
        161: "161",
        162: "162",
        163: "163",
        164: "164",
        165: "165",
        166: "166",
        167: "167",
        168: "168",
        169: "169",
        170: "170",
        171: "171",
        172: "172",
        173: "173",
        174: "174",
        175: "175",
        176: "176",
        177: "177",
        178: "178",
        179: "179",
        180: "180",
        181: "181",
        182: "182",
        183: "183",
        184: "184",
        185: "185",
        186: "186",
        187: "187",
        188: "188",
        189: "189",
        190: "190",
        191: "191",
        192: "192",
        193: "193",
        194: "194",
        195: "195",
        196: "196",
        197: "197",
        198: "198",
        200: "199",
        202: "200",
        204: "201",
        206: "202",
        208: "203",
        210: "204",
        214: "205",
        216: "206",
        217: "207",
        218: "208",
        219: "209",
        221: "210",
        222: "211",
        223: "212",
        224: "213",
        225: "214",
        226: "215",
        227: "216",
        228: "217",
        229: "218",
        230: "219",
        231: "220",
        232: "221",
        233: "222",
        234: "223",
        235: "224",
        236: "225",
        237: "226",
        238: "227",
        239: "228",
        240: "229",
        241: "230",
        242: "231",
        243: "232",
        244: "233",
        245: "234",
        246: "235",
        248: "236",
        249: "237",
        250: "238",
        251: "239",
        252: "240",
        253: "241",
        254: "242",
        255: "243",
        256: "244",
        257: "245",
        258: "246",
        259: "247",
        260: "248",
        261: "249",
        262: "250",
        263: "251",
        264: "252",
        265: "253",
        266: "254",
        267: "255",
        268: "256",
        269: "257",
        270: "258",
        271: "259",
        272: "260",
        273: "261",
        275: "262",
        276: "263",
        277: "264",
        278: "265",
        279: "266",
        280: "267",
        281: "268",
        282: "269",
        283: "270",
        284: "271",
        285: "272",
        286: "273",
        287: "274",
        288: "275",
        289: "276",
        290: "277",
        291: "278",
        292: "279",
        293: "280",
        294: "281",
        295: "282",
        296: "283",
        297: "284",
        298: "285",
        299: "286",
        300: "287",
        301: "288",
        302: "289",
        303: "290",
        304: "291",
        305: "292",
        306: "293",
        307: "294",
        308: "295",
        309: "296",
        310: "297",
        311: "298",
        312: "299",
        313: "300",
        314: "301",
        315: "302",
        316: "303",
        317: "304",
        318: "305",
        319: "306",
        320: "307",
        321: "308",
        322: "309",
        323: "310",
        324: "311",
        325: "312",
        326: "313",
        327: "314",
        328: "315",
        329: "316",
        330: "317",
        331: "318",
        332: "319",
        333: "320",
        334: "321",
        335: "322",
        336: "323",
        337: "324",
        338: "325",
        339: "326",
        340: "327",
        342: "328",
        343: "329",
        344: "330",
        345: "331",
        346: "332",
        347: "333",
        348: "334",
        349: "335",
        350: "336",
        351: "337",
        352: "338",
        353: "339",
        354: "340",
        355: "341",
        356: "342",
        357: "343",
        358: "344",
        359: "345",
        360: "346",
        361: "347",
        362: "348",
        363: "349",
        364: "350",
        365: "351",
        366: "352",
        367: "353",
        368: "354",
        369: "355",
        370: "356",
        371: "357",
        372: "358",
        373: "359",
        374: "360",
        375: "361",
        376: "362",
        377: "363",
        378: "364",
        379: "365",
        380: "366",
        381: "367",
        382: "368",
        383: "369",
        384: "370",
        385: "371",
        386: "372",
        387: "373",
        388: "374",
        389: "375",
        390: "376",
        391: "377",
        392: "378",
        393: "379",
        394: "380",
        395: "381",
        396: "382",
        397: "383",
        398: "384",
        399: "385",
        400: "386",
        401: "387",
        402: "388",
        403: "389",
        404: "390",
        405: "391",
        406: "392",
        407: "393",
        408: "394",
        409: "395",
        410: "396",
        411: "397",
        412: "398",
        413: "399",
        415: "400",
        416: "401",
        417: "402",
        418: "403",
        419: "404",
        420: "405",
        421: "406",
        422: "407",
        423: "408",
        424: "409",
        425: "410",
        426: "411",
        427: "412",
        428: "413",
        429: "414",
        430: "415",
        431: "416",
        432: "417",
        433: "418",
        434: "419",
        435: "420",
        436: "421",
        437: "422",
        438: "423",
        439: "424",
        440: "425",
        441: "426",
        442: "427",
        443: "428",
        444: "429",
        445: "430",
        446: "431",
        447: "432",
        448: "433",
        449: "434",
        450: "435",
        451: "436",
        452: "437",
        453: "438",
        454: "439",
        455: "440",
        456: "441",
        457: "442",
        458: "443",
        459: "444",
        460: "445",
        461: "446",
        462: "447",
        463: "448",
        464: "449",
        465: "450",
        466: "451",
        467: "452",
        468: "453",
        469: "454",
        470: "455",
        473: "456",
        474: "457",
        475: "458",
        476: "459",
        477: "460",
        478: "461",
        479: "462",
        480: "463",
        481: "464",
        482: "465",
        483: "466",
        484: "467",
        485: "468",
        486: "469",
        487: "470",
        488: "471",
        489: "472",
        490: "473",
        491: "474",
        492: "475",
        493: "476",
        494: "477",
        495: "478",
        496: "479",
        497: "480",
        498: "481",
        499: "482",
        500: "483",
        501: "484",
        502: "485",
        503: "486",
        504: "487",
        505: "488",
        506: "489",
        507: "490",
        508: "491",
        509: "492",
        510: "493",
        511: "494",
        512: "495",
        513: "496",
        514: "497",
        515: "498",
        516: "499",
        517: "500",
        518: "501",
        519: "502",
        520: "503",
        521: "504",
        522: "505",
        523: "506",
        524: "507",
        525: "508",
        526: "509",
        527: "510",
        528: "511",
        529: "512",
        530: "513",
        531: "514",
        532: "515",
        533: "516",
        534: "517",
        535: "518",
        536: "519",
        537: "520",
        538: "521",
        539: "522",
        540: "523",
        541: "524",
        542: "525",
        543: "526",
        544: "527",
        545: "528",
        546: "529",
        547: "530",
        548: "531",
        549: "532",
        550: "533",
        551: "534",
        552: "535",
        553: "536",
        554: "537",
        555: "538",
        556: "539",
        557: "540",
        558: "541",
        559: "542",
        560: "543",
        561: "544",
        562: "545",
        563: "546",
        564: "547",
        565: "548",
        566: "549",
        567: "550",
        568: "551",
        569: "552",
        570: "553",
        571: "554",
        572: "555",
        573: "556",
        574: "557",
        575: "558",
        576: "559",
        577: "560",
        578: "561",
        579: "562",
        580: "563",
        581: "564",
        582: "565",
        583: "566",
        584: "567",
        585: "568",
        586: "569",
        587: "570",
        588: "571",
        589: "572",
        590: "573",
        591: "574",
        592: "575",
        593: "576",
        594: "577",
        595: "578",
        596: "579",
        597: "580",
        598: "581",
        599: "582",
        600: "583",
        601: "584",
        602: "585",
        603: "586",
        604: "587",
        605: "588",
        606: "589",
        607: "590",
        608: "591",
        609: "592",
        610: "593",
        611: "594",
        612: "595",
        613: "596",
        614: "597",
        615: "598",
        616: "599",
        617: "600",
        618: "601",
        619: "602",
        620: "603",
        621: "604",
        622: "605",
        623: "606",
        624: "607",
        625: "608",
        626: "609",
        627: "610",
        628: "611",
        629: "612",
        630: "613",
        631: "614",
        632: "615",
        633: "616",
        634: "617",
        635: "618",
        636: "619",
        637: "620",
        638: "621",
        639: "622",
        640: "623",
        641: "624",
        642: "625",
        643: "626",
        644: "627",
        645: "628",
        646: "629",
        647: "630",
        648: "631",
        649: "632",
        650: "633",
        651: "634",
        652: "635",
        653: "636",
        654: "637",
        655: "638",
        656: "639",
        657: "640",
        658: "641",
        659: "642",
        660: "643",
        661: "644",
        662: "645",
        663: "646",
        664: "647",
        665: "648",
        666: "649",
        667: "650",
        668: "651",
        669: "652",
        670: "653",
        671: "654",
        672: "655",
        673: "656",
        674: "657",
        675: "658",
        676: "659",
        677: "660",
        678: "661",
        679: "662",
        680: "663",
        681: "664",
        682: "665",
        683: "666",
        684: "667",
        685: "668",
        686: "669",
        687: "670",
        688: "671",
        689: "672",
        690: "673",
        691: "674",
        692: "675",
        693: "676",
        694: "677",
        695: "678",
        696: "679",
        697: "680",
        698: "681",
        699: "682",
        700: "683",
        701: "684",
        702: "685",
        703: "686",
        704: "687",
        705: "688",
        706: "689",
        707: "690",
        708: "691",
        709: "692",
        710: "693",
        711: "694",
        712: "695",
        713: "696",
        714: "697",
        715: "698",
        716: "699",
        717: "700",
        718: "701",
        719: "702",
        720: "703",
        721: "704",
        722: "705",
        723: "706",
        724: "707",
        725: "708",
        726: "709",
        727: "710",
        728: "711",
        729: "712",
        730: "713",
        731: "714",
        732: "715",
        733: "716",
        734: "717",
        735: "718",
        736: "719",
        737: "720",
        738: "721",
        739: "722",
        740: "723",
        741: "724",
        742: "725",
        743: "726",
        744: "727",
        745: "728",
        746: "729",
        747: "730",
        748: "731",
        749: "732",
        750: "733",
        751: "734",
        752: "735",
        753: "736",
        754: "737",
        755: "738",
        756: "739",
        757: "740",
        758: "741",
        759: "742",
        760: "743",
        761: "744",
        762: "745",
        763: "746",
        764: "747",
        765: "748",
        766: "749",
        767: "750",
        768: "751",
        769: "752",
        770: "753",
        771: "754",
        772: "755",
        773: "756",
        774: "757",
        775: "758",
        776: "759",
        777: "760",
        778: "761",
        779: "762",
        780: "763",
        781: "764",
        782: "765",
        783: "766",
        784: "767",
        785: "768",
        786: "769",
        787: "770",
        788: "771",
        789: "772",
        790: "773",
        791: "774",
        792: "775",
        793: "776",
        794: "777",
        795: "778",
        796: "779",
        797: "780",
        798: "781",
        799: "782",
        800: "783",
        801: "784",
        802: "785",
        803: "786",
        804: "787",
        805: "788",
        806: "789",
        807: "790",
        808: "791",
        809: "792",
        810: "793",
        811: "794",
        812: "795",
        813: "796",
        814: "797",
        815: "798",
        816: "799",
        817: "800",
        818: "801",
        819: "802",
        820: "803",
        821: "804",
        822: "805",
        823: "806",
        824: "807",
        825: "808",
        826: "809",
        827: "810",
        828: "811",
        829: "812",
        830: "813",
        831: "814",
        832: "815",
        833: "816",
        834: "817",
        835: "818",
        836: "819",
        837: "820",
        838: "821",
        839: "822",
        840: "823",
        841: "824",
        842: "825",
        843: "826",
        844: "827",
        845: "828",
        846: "829",
        847: "830",
        848: "831",
        849: "832",
        850: "833",
        851: "834",
        852: "835",
        853: "836",
        854: "837",
        855: "838",
        856: "839",
        857: "840",
        858: "841",
        859: "842",
        860: "843",
        861: "844",
        862: "845",
        863: "846",
        864: "847",
        865: "848",
        866: "849",
        867: "850",
        868: "851",
        869: "852",
        870: "853",
        871: "854",
        872: "855",
        873: "856",
        874: "857",
        875: "858",
        876: "859",
        877: "860",
        878: "861",
        879: "862",
        880: "863",
        881: "864",
        882: "865",
        883: "866",
        884: "867",
        885: "868",
        886: "869",
        887: "870",
        888: "871",
        889: "872",
        890: "873",
        891: "874",
        892: "875",
        893: "876",
        894: "877",
        895: "878",
        896: "879",
        897: "880",
        898: "881",
        899: "882",
        900: "883",
        901: "884",
        902: "885",
        903: "886",
        904: "887",
        905: "888",
        906: "889",
        907: "890",
        908: "891",
        909: "892",
        910: "893",
        911: "894",
        912: "895",
        913: "896",
        914: "897",
        915: "898",
        916: "899",
        917: "900",
        918: "901",
        919: "902",
        920: "903",
        921: "904",
        922: "905",
        923: "906",
        924: "907",
        925: "908",
        926: "909",
        927: "910",
        928: "911",
        929: "912",
        930: "913",
        931: "914",
        932: "915",
        933: "916",
        934: "917",
        935: "918",
        936: "919",
        937: "920",
        938: "921",
        939: "922",
        940: "923",
        941: "924",
        942: "925",
        943: "926",
        944: "927",
        945: "928",
        946: "929",
        947: "930",
        948: "931",
        949: "932",
        950: "933",
        951: "934",
        952: "935",
        953: "936",
        954: "937",
        955: "938",
        956: "939",
        957: "940",
        958: "941",
        959: "942",
        960: "943",
        961: "944",
        962: "945",
        963: "946",
        964: "947",
        965: "948",
        966: "949",
        967: "950",
        968: "951",
        969: "952",
        970: "953",
        971: "954",
        972: "955",
        973: "956",
        974: "957",
        975: "958",
        976: "959",
        977: "960",
        978: "961",
        979: "962",
        980: "963",
        981: "964",
        982: "965",
        983: "966",
        984: "967",
        985: "968",
        986: "969",
        987: "970",
        988: "971",
        989: "972",
        990: "973",
        991: "974",
        992: "975",
        993: "976",
        994: "977",
        995: "978",
        996: "979",
        997: "980",
        998: "981",
        999: "982",
        1000: "983",
        1001: "984",
        1002: "985",
        1003: "986",
        1004: "987",
        1005: "988",
        1006: "989",
        1007: "990",
        1008: "991",
        1009: "992",
        1010: "993",
        1011: "994",
        1012: "995",
        1013: "996",
        1014: "997",
        1015: "998",
        1016: "999",
        1017: "1000",
        1018: "1001",
        1019: "1002",
        1020: "1003",
        1021: "1004",
        1022: "1005",
        1023: "1006",
        1024: "1007",
        1025: "1008",
        1026: "1009",
        1027: "1010",
        1028: "1011",
        1029: "1012",
        1030: "1013",
        1031: "1014",
        1032: "1015",
        1033: "1016",
        1034: "1017",
        1035: "1018",
        1036: "1019",
        1037: "1020",
        1038: "1021",
        1039: "1022",
        1040: "1023",
        1041: "1024",
        1042: "1025",
        1043: "1026",
        1044: "1027",
        1045: "1028",
        1046: "1029",
        1047: "1030",
        1048: "1031",
        1049: "1032",
        1050: "1033",
        1051: "1034",
        1052: "1035",
        1053: "1036",
        1054: "1037",
        1055: "1038",
        1056: "1039",
        1057: "1040",
        1058: "1041",
        1059: "1042",
        1060: "1043",
        1061: "1044",
        1062: "1045",
        1063: "1046",
        1064: "1047",
        1065: "1048",
        1066: "1049",
        1067: "1050"
      };
      let inputForMidas = {
        parent: this.mainWebGL,
        file: "./cm/625.mct",
        check: 2,
        visible: false,
        extra: {
          midas: {
            fun: {
              replaceNode: function (data) {
                console.log(data, "ssssssssss");
                let oldData = JSON.parse(JSON.stringify(data.nodes));
                console.log(oldData);
                data.nodes = [];
                for (let i in nodeJSON) {
                  data.nodes[i] = JSON.parse(JSON.stringify(oldData[nodeJSON[i]]));
                }
              },
              replaceElement: function (data) {
                console.log(data);
                let oldData = JSON.parse(JSON.stringify(data.elements));
                console.log(oldData);
                data.elements = [];
                for (let i in elemJSON) {
                  if (typeof oldData[elemJSON[i]] != "undefined" && oldData[elemJSON[i]]) {
                    data.elements[i] = JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                    console.log(i);
                    if (i == 451) {
                      let agc = 1;
                    }
                    for (let j in data.elements[i].iNs) {
                      data.elements[i].iNs[j] = nodeJSONOld[data.elements[i].iNs[j]];
                    }
                  }
                  // JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                }
              }
            },
            //     data: {
            //         res: meshCM.info.data.res,
            //         str: meshCM.info.data.str,
            //     },
            resOBJ: meshCM
          }
        },
        // rotate:{x:-90},//{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            // CM.info.data.res = meshCM.info.data.res;
            // CM.info.data.str = meshCM.info.data.str;
          }
        }
      };
      //init midas
      meshMidas = new CMMidas(inputForMidas);
      this.mainWebGL.CMs.push(meshMidas);
      ///////////////////////////////////////////////////////////////////////////////////
      //huag up to global ENV,test only
      window.mainW = this.mainWebGL;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.win {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 150px !important;
}
::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.tem_l_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}
.tem_l_img img {
  width: 320px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
#lut_10_2 {
  position: absolute;
  // z-index: 99;
  // top: 120px !important;
}
.pos-rel {
  position: relative;
}
.radus24 {
  // background: #102048;
  border-radius: 24px;
}

.tem_l {
  margin-left: 10px;
  width: 418px;
  height: 100%;
  margin-right: 16px;
}

.lut_10_class_2 {
  top: -7px;
  left: 26px;
  width: 300px;
  height: 300px;
  position: absolute;
}
::v-deep .el-input {
  height: 32px;
}
.gui_10_class {
  top: 35px;
  right: 5px;
  position: absolute;
}

.helper_10_class_2 {
  bottom: 50px;
  left: 26px;
  width: 128px;
  height: 128px;
  position: absolute;
}

#loading_1 {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  z-index: 3;
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../../assets/images/gif.gif");
  background-size: 100% 100%;
}
.head-load-form {
  // z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.pad26 {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}
.tem_container_10_class {
  top: 99px;
  left: 20px;
  right: 0px;
  bottom: 20px;
  position: absolute;
  // background: #102048;
  // border-radius: 24px;
}
.container_10_class_2 {
  height: 100%;
  width: 100%;
}

.btnStyle {
  border: 1px solid #2667db;
  text-align: center;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
}

.active-bg {
  background-color: #2667db;
}

.pointer {
  cursor: pointer;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

@media screen and (min-width: 1880px) {
  .el-form-custom .sim-position .el-input {
    width: 100% !important;
  }
}

.load-text {
  width: 810px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -405px;
  bottom: 50px;
}

.text-pos {
  margin-left: -45px;
}
</style>
