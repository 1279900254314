<template>
  <div>
    <!-- <div id="loading_1" name="loading_1"  class=""></div> -->
    <!-- <div class="head-load-form pad26">
      <el-form class="el-form-custom mB20" :model="formData" :inline="true">
        <el-form-item label="仿真类型">
          <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptions">
            <el-option v-for="item in typeOptions" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="仿真结果">
          <el-select v-model="formData.typeId" placeholder="请选择" @change="getResultOptions">
            <el-option v-for="item in targetOptions" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
      </el-form>
      <el-form class="el-form-custom mB20" :model="formData" :inline="true">
        <el-form-item>
          <el-button @click="getPavement('1')" class="btnStyle pointer" :class="{ 'active-bg': pavement == 1 }">左幅</el-button>
          <el-button @click="getPavement('2')" class="btnStyle pointer" :class="{ 'active-bg': pavement == 2 }">右幅</el-button>
        </el-form-item>
        <el-form-item label="截面位置">
          <el-select v-model="formData.positionGroupId" placeholder="请选择" @change="getPosition" class="sim-position" style="width: 240px">
            <el-option v-for="item in positionOptions" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="仿真节点">
          <el-select v-model="formData.positionId" placeholder="请选择" @change="getTargetPosition">
            <el-option v-for="item in targetPositionOptions" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
        </el-form-item>
        
      </el-form>
    </div> -->
    <meshForm @meshResultSelect="meshResultSelect" ref="meshform" :meshFlag="meshFlag"></meshForm>
    <!-- <el-button @click="moduleChange">点击切换{{ i }}</el-button> -->
    <!-- <div class="box mB10 win">
      
      <div class="fs18 noFlex" style="width: 86px">仿真场</div>
      <el-select v-model="valueNum" placeholder="请选择" @change="getValue">
        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div> -->

    <div id="container_10" style="top: 0px; left: 0px; width: 100%; height: 100%; position: absolute"></div>
    <canvas id="lut_10" width="250" height="450" style="top: 60px; left: 10px; width: 250px; height: 450px; position: absolute"></canvas>
    <div id="gui_10" class="gui_10_class"></div>
    <div id="helper_10" style="bottom: 50px; left: 10px; width: 128px; height: 128px; position: absolute"></div>
    <!--    class="container_10_class"  class="lut_10_class"   class="helper_10_class"      -->
    <div class="load-text">
      <div>
        <p>重庆三圣特大桥为主跨（80+3×150+80）m 预应力混凝土连续刚构桥，主梁采用单箱单室截面，</p>
        <p>顶板宽 12.0m，底板宽 6.5m，顶板悬臂长度 2.75m；箱梁跨中梁高 3.3m，根部梁高 9.3m，梁底变化曲线为 1.7 次抛物线。</p>
      </div>
      <div style="margin-top: 8px">
        <p>主梁采用挂篮悬臂对称浇筑施工，</p>
        <p>梁段 1~6#长度为 3.0m，7~12#长度为 3.5m，13~19#长度为 4.0m</p>
      </div>
    </div>
  </div>
</template>

<script>
// import { showMesh } from "../jsm/threemain/showmesh"; // ./jsm/threemain/showmesh   tomcloudmap
import { Guidat, ViewHelper, LUT_text, CMThree, appShowMesh } from "tomcloudmap";

import meshForm from "./common/meshForm";
export default {
  name: "show",
  props: {},
  components: { meshForm },
  data() {
    return {
      meshFlag: true,
      i: 1,
      valueNum: "U.U",
      // 'U.U', 'U.V', 'U.W', 'U.M', 'S.S11', 'S.S22', 'S.S33', 'S.S23', 'S.S13', 'S.S12'
      list: [
        { id: "U.U", name: "U.U(位移)" },
        { id: "U.V", name: "U.V(位移-X方向)" },
        { id: "U.W", name: "U.W(位移-Y方向)" },
        { id: "U.M", name: "U.M(位移-Z方向)" },
        { id: "S.S11", name: "S.S11(正应力-X方向)" },
        { id: "S.S22", name: "S.S22(正应力-Y方向)" },
        { id: "S.S33", name: "S.S33(正应力-Z方向)" },
        { id: "S.S23", name: "S.S23(剪应力-YZ方向)" },
        { id: "S.S13", name: "S.S13(剪应力-XZ方向)" },
        { id: "S.S12", name: "S.S12(剪应力-XY方向)" }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {
    // this.i = this.$route.query.i || 1;
    // console.log("mounted--i", this.i);
    // this.moduleVisbile(`./zip/${this.i}.zip`);
    // this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    this.moduleVisbile();
    // this.getSection();
  },
  watch: {},
  methods: {
    // 云图交互
    meshResultSelect(id) {
      if (this.meshFlag) return;

      console.log("meshResultSelect-id", id, this.meshFlag, mainG.CMs[0]);
      switch (id) {
        case "2-1":
          mainG.CMs[0].updateColors("unoda0.u");
          break;
        case "2-2":
          mainG.CMs[0].updateColors("unoda0.v");
          break;
        case "2-3":
          mainG.CMs[0].updateColors("unoda0.w");
          break;
        case "2-4":
          mainG.CMs[0].updateColors("unoda1.anx");
          break;
        case "2-5":
          mainG.CMs[0].updateColors("unoda1.any");
          break;
        case "2-6":
          console.log("meshResultSelect-id", id);
          mainG.CMs[0].updateColors("unoda1.anz");
          break;
      }
    },
    getValue() {
      console.log("云图", this.valueNum);
      this.main.CM.show(this.valueNum);
      this.main.render();
    },
    // moduleChange() {
    //   this.i++;
    //   console.log("i", this.i);
    //   if (this.i >= 6) {
    //     this.i = 1;
    //   }
    //   let url = `./zip/${this.i}.zip`;
    //   console.log("url", url);
    //   this.moduleVisbile(url);
    // },
    moduleVisbile(url) {
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });

      this.main = {};
      let thisOfVUEentry = this;
      // let cmment = function () {
      //   thisOfVUEentry.main.worldBox.rotateY(Math.PI);
      //   thisOfVUEentry.main.worldBox.rotateX((-90 / 180) * Math.PI);
      //   // thisOfVUEentry.main.render();
      //   thisOfVUEentry.main.CM.show(thisOfVUEentry.main.CM.getPressureSName()[0]);

      //   thisOfVUEentry.main.LUT.setTextColor(0xffffff);
      //   thisOfVUEentry.main.perpCamera.position.x = 1.4716178305697283;
      //   thisOfVUEentry.main.perpCamera.position.y = 35;
      //   thisOfVUEentry.main.perpCamera.position.z = 78;
      //   thisOfVUEentry.main.render();
      //   setTimeout(function () {
      //     thisOfVUEentry.main.perpCamera.position.y = 35;
      //     thisOfVUEentry.main.perpCamera.position.z = 78;
      //     thisOfVUEentry.main.render();
      //   }, 50);
      // };

      let logRouter = function (msg) {
        console.log("Log Router :", msg);
        thisOfVUEentry.loadingPage.close();
      };

      let value = {
        // DIV: {
        //   container: "container_10",
        //   helper: "helper_10",
        //   lut: "lut_10",
        //   gui: "gui_10"
        // }
        // log: abc,
        DIV: "container_10",
        containerType: "div", //div or object
        ctl: "orbit", // orbit or traceball
        log: logRouter, //all log
        setting: {
          cameraAuto: false, // ture or false ,if the value is true or undefined ,below settings will be disable
          cameraPosition: [], //[10, 0, 0],//x,y,z
          cameraMartix: false, //false or 4x4 martix ,if there has a array of matrix ,position will be igione
          cameraLookat: [], //x,y,z
          ctlTarget: [0, 0, 0], //x,y,z
          ////////////////////////////////////
          realTimeRender: false //
        },

        fun: {
          // beforeInit,init,afterinit of webGL's
          afterInit: function (/*that*/) {},
          init: function (/*that*/) {},
          onMouseClick: function (mainGL, raycaster, xy) {
            console.log(raycaster, xy);
            if (mainGL.octree) {
              let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
              mainGL.log("point id :", index);
            }
          },
          onMouseMove: function (mainGL, raycaster, xy) {
            console.log(raycaster, xy);
            if (mainGL.octree) {
              let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
              mainGL.log("point id :", index);
            }
          }
        }
      };

      // init webGL
      this.mainWebGL = new appShowMesh(value);
      ///////////////////////////////////////////////////////////////////////////////////
      //init LUT
      // must be init before CM init
      let inputValueLUT = {
        DIV: "lut_10",
        containerType: "div", //div or object
        kind: "curve", // curve ,default
        fontColor: { r: 1, g: 1.0, b: 1 },
        parent: this.mainWebGL
      };
      this.mainWebGL.LUT = new LUT_text(inputValueLUT);

      ///////////////////////////////////////////////////////////////////////////////////
      //init viewHelper
      let inputValueViewHelper = {
        // DIV: "viewhelper",
        DIV: "helper_10",
        containerType: "div", //div or object
        perpCamera: this.mainWebGL.perpCamera,
        parent: this.mainWebGL
      };

      this.mainWebGL.viewHelper = new ViewHelper(inputValueViewHelper);

      ///////////////////////////////////////////////////////////////////////////////////
      // value of CM mesh
      let inputForCM = {
        parent: this.mainWebGL,
        file: "./cm/badusan.zip",
        check: 2,
        // extra:{
        //     str:{
        //         str:
        //     }
        // },
        rotate: { x: 90 }, //{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            CM.initPickUp(CM);

            thisOfVUEentry.meshFlag = false;
            console.log("云图加载完成", thisOfVUEentry);
            thisOfVUEentry.$refs["meshform"].getTargetOptions(1);
          }
        },
        CM: {
          // type:"gid/inp",
          region: true //true/false
        },

        center: true, //true,false
        process: {
          add: {},
          edit: [
            {
              type: "point",
              nodes: [],
              value: [{}]
            },
            {
              type: "generate",
              nodes: [],
              value: [{}]
            }
          ],
          del: {}
        }
      };

      //init Mesh
      let meshCM = new CMThree(inputForCM);
      this.mainWebGL.CMs.push(meshCM);

      ///////////////////////////////////////////////////////////////////////////////////
      //huag up to global ENV,test only
      window.mainG = this.mainWebGL;
      console.log("云图加载最后全家赋值", window.mainG);
      // let that = this;
      // let mesh = {
      //   // zip: "./zip/1.zip",
      //   // zip: url,
      //   zip: "./zip/zh.zip",
      //   typeCM: true,
      //   type: "gid", // inp
      //   // type: "abaqus", // inp
      //   ziped: true,
      //   fun: {
      //     // menu: 2,
      //     cmment: cmment,
      //     addon: function () {
      //       console.log(that.main.CM.getPressureSName());
      //       // that.list = that.main.CM.getPressureSName();
      //       that.valueNum = that.list[0].id;
      //       that.getValue();

      //       // let abc=document.getElementById("loading_1")
      //       // abc.style.display="none";

      //       that.loadingPage.close();
      //     }
      //   }
      // };

      // this.main = new showMesh(value);
      // this.main.import(mesh);
      // window.main = this.main;
    }
  }
};
</script>

<style scoped lang="scss">
.win {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 150px !important;
}

#lut_10 {
  position: absolute;
  // z-index: 99;
  // top: 120px !important;
}
.lut_10_class {
  top: 120px;
  left: 56px;
  width: 250px;
  height: 450px;
  position: absolute;
}
::v-deep .el-input {
  height: 32px;
}
.gui_10_class {
  top: 35px;
  right: 5px;
  position: absolute;
}

.helper_10_class {
  bottom: 50px;
  left: 56px;
  width: 128px;
  height: 128px;
  position: absolute;
}

#loading_1 {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  z-index: 3;
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../../assets/images/gif.gif");
  background-size: 100% 100%;
}
.head-load-form {
  // z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.pad26 {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}
.container_10_class {
  top: 99px;
  left: 36px;
  right: 36px;
  bottom: 22px;
  position: absolute;
  background: #102048;
  border-radius: 24px;
}

.btnStyle {
  border: 1px solid #2667db;
  text-align: center;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
}

.active-bg {
  background-color: #2667db;
}

.pointer {
  cursor: pointer;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

@media screen and (min-width: 1880px) {
  .el-form-custom .sim-position .el-input {
    width: 100% !important;
  }
}

.load-text {
  width: 810px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -405px;
  bottom: 50px;
}
</style>
