<template>
  <div class="modelIndex">
    <div class="mainBox box box_column">
      <div class="box justify padL46" v-if="false">
        <ul class="ul1 box center ulTab">
          <li class="textC pointer box center" v-for="(item, index) in list" :key="index" :class="active == item.id ? 'tab1' : ''" @click="changeTab(item.id)">
            <!-- <div style="width:100%">
                        <div>{{item.name}}</div> -->
            <!-- <div  v-if="active == item.id" class="fu">
                            <img src="../../../assets/images/Rectangle_bar.png" alt="">
                        </div>
                        <div>
                            <img src="" alt="">
                        </div> -->
            <!-- </div> -->
            <div style="width: 100%" class="fu">
              <div>{{ item.name }}</div>
              <div class="tiao" v-if="active == item.id"></div>
            </div>
            <div class="shu" v-if="index != list.length - 1"></div>
          </li>
        </ul>
        <div>
          <!-- <el-button @click="addData" class="mL44 dataQuery">新建方案</el-button> -->
        </div>
      </div>
      <div class="heng" v-if="false"></div>
      <div class="box justify" v-if="active == 2">
        <el-form class="el-form-custom mB20" :model="formData" :inline="true">
          <el-form-item label="分析方式">
            <el-select v-model="formData.typeGroupId" placeholder="请选择" @change="getTargetOptions">
              <el-option v-for="item in typeOptions" :key="item.id" :value="item.id" :label="item.name" />
            </el-select>
          </el-form-item>
          <span v-if="analysis == 1" class="mar-l24">
            <el-form-item label="分析测点">
              <el-select v-model="formData.typeId" placeholder="请选择" @change="analysisMethod">
                <el-option v-for="item in targetOptions" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="分析时刻"> <el-input v-model="formData.code" placeholder="请输入基准温度" style="width: 100px" /><span style="padding-left: 8px">℃</span> </el-form-item> -->
            <el-form-item prop="analysisTime" label="分析时刻">
              <el-date-picker style="width: 230px" @change="clearTable(1)" placeholder="默认当前-5分钟" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="formData.analysisTime"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              温度 <span>{{ formData.temperature }}℃</span>
            </el-form-item>
            <el-form-item label="基准温度"> <el-input v-model="formData.baseTem" placeholder="请输入基准温度" style="width: 100px" /><span style="padding-left: 8px">℃</span> </el-form-item>
          </span>
          <span v-if="analysis == 2" class="mar-l24">
            <el-form-item label="温差"> <el-input v-model="formData.temDiff" placeholder="请输入温差" style="width: 100px" /><span style="padding-left: 8px">℃</span> </el-form-item>
          </span>
          <span v-if="analysis == 3" class="mar-l24">
            <!-- <el-form-item label="分析测点1">
              <el-select v-model="formData.typeId1" placeholder="请选择" @change="analysisMethod1">
                <el-option v-for="item in targetOptions1" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item label="分析测点2">
              <el-select v-model="formData.typeId2" placeholder="请选择" @change="analysisMethod2">
                <el-option v-for="item in targetOptions2" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item>

            <el-form-item label="分析测点3">
              <el-select v-model="formData.typeId3" placeholder="请选择" @change="analysisMethod3">
                <el-option v-for="item in targetOptions3" :key="item.id" :value="item.id" :label="item.name" />
              </el-select>
            </el-form-item> -->



            <el-form-item prop="analysisTime2" label="分析时刻">
              <el-date-picker style="width: 230px" @change="clearTable(2)" placeholder="默认当前-5分钟" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-model="formData.analysisTime2"> </el-date-picker>
            </el-form-item>
            <el-form-item>
              T1温度 <span>{{ formData.t1 }}℃</span>
            </el-form-item>
            <el-form-item>
              <span style="margin-left: 10px">
                T2温度 <span>{{ formData.t2 }}℃</span>
              </span>
            </el-form-item>

            <!-- <el-form-item label="分析时刻"> <el-input v-model="formData.code" placeholder="默认当前5分钟" style="width: 100px" /><span style="padding-left: 8px">℃</span> </el-form-item> -->
          </span>
          <span v-if="analysis == 4" class="mar-l24">
            <el-form-item label="测点1温度"> <el-input v-model="formData.temGradient1" placeholder="请输入温度" style="width: 104px" /><span style="padding-left: 8px">℃</span> </el-form-item>
            <el-form-item label="测点2温度"> <el-input v-model="formData.temGradient2" placeholder="请输入温度" style="width: 104px" /><span style="padding-left: 8px">℃</span> </el-form-item>
          </span>
        </el-form>

        <el-form class="el-form-custom mB20" :inline="true">
          <el-form-item>
            <el-button @click="calculation" class="pointer">在线计算</el-button>
          </el-form-item>

          <!-- <el-form-item>
          <el-input v-model="formData.code" placeholder="请输入测点编号" />
        </el-form-item> -->
        </el-form>
      </div>
      <div class="flex1 main simulation">
        <!-- <div v-if="active == 1">
          <online></online>
        </div>
        <div v-if="active == 2" style="height: 98%" class="main2">
          <modelAnalysisShow></modelAnalysisShow>
        </div>
        <div v-if="active == 3">
          <plan></plan>
        </div> -->

        <!-- <deadLoad v-show="active == 1" class="model-show"></deadLoad> -->
        <temperatureLoadSansheng v-if="projectId == 10032" :active="active" ref="meshActive"></temperatureLoadSansheng>
        <temperatureLoadBadusan v-if="projectId == 10034" :active="active" ref="meshActive"></temperatureLoadBadusan>
        <temperatureLoadDawang v-if="projectId == 10046" :active="active" ref="meshActive"></temperatureLoadDawang>
        <temperatureLoadXiangmihu v-if="projectId == 84" :active="active" ref="meshActive"></temperatureLoadXiangmihu>
      </div>
    </div>
  </div>
</template>

<script>
import deadLoad from "./deadLoad";
import temperatureLoadDawang from "./temperatureLoad_dawang";
import temperatureLoadBadusan from "./temperatureLoad_badusan";
import temperatureLoadSansheng from "./temperatureLoad_sansheng";
import temperatureLoadXiangmihu from "./temperatureLoad_xiangmihu";
// import temperatureLoadXiangmihu from "./temperatureLoad_xiangmihu1";
import temperatureLoad from "./temperatureLoad";
import { analysisOptions } from "./common/mesh";
// import plan from "./plan";
import moment from "moment";
export default {
  name: "modelIndex",
  components: {
    // online,
    // modelAnalysisShow,
    // plan,
    temperatureLoad,
    temperatureLoadDawang,
    temperatureLoadBadusan,
    temperatureLoadSansheng,
    temperatureLoadXiangmihu,
    deadLoad
  },
  data() {
    return {
      analysis: 1,
      list: [
        { id: "1", name: "自重荷载" },
        { id: "2", name: "温度荷载" },
        { id: "3", name: "地震荷载" }
      ],
      active: "2",
      type: "add",

      //  窗口
      win: 1,
      ruleForm: { period: 2, startTime: "", endTime: "", year: "", modalTimeLag: 10, modalNMin: 1, modalNMax: 10 },
      open: "0",
      formData: {
        temGradient1: "",
        temGradient2: "",
        typeGroupId: "1",
        coefficient: "",
        coefficient1: "",
        coefficient2: "",
        typeId: "",
        code: "20",
        analysisTime: "",
        type: "a",
        temperature: "",
        temperature1: "",
        temperature2: "",
        baseTem: 18,
        baseTem2: "",
        temDiff: "",
        typeId1: "",
        typeId2: "",
        typeId3: "",
        analysisTime2: "",
        t1: "",
        t2:""
      },
      targetOptions: [],
      targetOptions1: [],
      targetOptions2: [],
      targetOptions3: [],
     
      typeOptions: analysisOptions,
      projectId: "",
      temp: "",
      temp1: "",
      flag: true
    };
  },
  //创建
  created() {
    console.log("在线仿真route", this.$route);
    this.active = this.$route.query.active || 2;
  },
  activated() {
    console.log("mnnmnm");
  },
  //安装
  async mounted() {
    this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    this.getTemAnalysis();
    this.getTemAnalysis1();
    this.getTemAnalysis2();
    this.getTemAnalysis3();
    this.getLastTem();

    // let nodeMap = "./cm/node.map";
    // let elementsMap = "./cm/elem.map";
    // let nodeJSON_text = await this.getUrl(nodeMap);
    // let elemJSON_text = await this.getUrl(elementsMap);
    // console.log(elemJSON_text)
    // let node = this.formatMap(nodeJSON_text);
    // let elem = this.formatMap(elemJSON_text);
    // console.log(JSON.stringify(node.outJson),JSON.stringify( node.outOldJson),'node, elem')
  },

  //方法
  methods: {
    // async getUrl(url) {
    //     let xml = new XMLHttpRequest();
    //     xml.open("GET", url);
    //     xml.send();
    //     let text = await new Promise((resolve, reject) => {
    //         xml.onload = () => {
    //             console.log("OK", xml);
    //             if (xml.status == 200) {
    //                 resolve(xml.response);
    //             } else if (xml.status == 404) {
    //                 reject(new Error(xml.status));
    //             } else {
    //                 reject(new Error("error"));
    //             }
    //         };
    //     });
    //     return text;
    // },
    // formatMap(text) {
    //     let outJson = {};
    //     let outOldJson = {};

    //     let lines = text.split("\n");
    //     for (let perLine of lines) {
    //         if (perLine != "") {
    //             let perArray = perLine.split(/\s+/g);
    //             outJson[perArray[1]] = perArray[0];
    //             outOldJson[perArray[0]] = perArray[1];
    //         }
    //     }
    //     return { outJson, outOldJson };
    // },
    getLastTem(){
      if (!this.formData.analysisTime2) {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 5 * 60 * 1000;
          this.formData.analysisTime2 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      }
      var params = {
        projectId:this.projectId,
        endTime:this.formData.analysisTime2
      }
      this.$axios.get(`${this.baseURL}simulation/last/tem`, { params }).then((res) => {
        res = res.data
        if (res.errCode === "200") {
          this.formData.t1 = res.data.T1
          this.formData.t2 = res.data.T2
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    getTargetOptions(e) {
      console.log("fenxicedian1111", e);
      this.analysis = e;
      if (e == 1 || e == 2) {
        this.formData.type = "a";
      } else {
        this.formData.type = "b";
      }
    },

    analysisMethod(e) {
      console.log("fenxicedian", e);
      this.formData.coefficient = this.getCoefficient(this.targetOptions, e);
      this.getTemAnalysisData("1");
    },
    analysisMethod1(e) {
      console.log("fenxicedian", e);
      this.formData.coefficient1 = this.getCoefficient(this.targetOptions1, e);
      this.getTemAnalysisData("2");
    },
    analysisMethod2(e) {
      console.log("fenxicedian", e);
      this.formData.coefficient2 = this.getCoefficient(this.targetOptions2, e);
      this.getTemAnalysisData("3");
    },
    analysisMethod3(e) {
      console.log("fenxicedian", e);
      this.formData.coefficient3 = this.getCoefficient(this.targetOptions3, e);
      this.getTemAnalysisData("4");
    },
    getCoefficient(arr, id) {
      let n = arr.length;
      for (let i = 0; i < n; i++) {
        if (arr[i].id == id) {
          return arr[i].coefficient;
        }
      }
    },
    clearTable(e) {
      console.log("分析时刻", e, this.formData.analysisTime);
      if (e == 1) {
        this.getTemAnalysisData(1);
      } else {
        this.getTemAnalysisData(2);
        this.getTemAnalysisData(3);
        this.getTemAnalysisData(4);
        this.getLastTem()
      }
    },
    // 计算
    calculation() {
      // 1 整体温度效应  温度-基准温度   2 温度梯度  T1-T2  （T1-T2）*T2系数
      //   3.15  测点  ）温度梯度：T1=(WD1-JGWD3),T2=（JGWD1-JGWD3）
      //  
      let data;
      if (this.analysis == 1) {
        let tem = this.formData.temperature - this.formData.baseTem;
        data = {
          projectId:this.projectId,
          type: this.formData.type,
          temp: tem.toString()
        };
      }
      if (this.analysis == 2) {
        data = {
          projectId:this.projectId,
          type: this.formData.type,
          temp: this.formData.temDiff.toString()
        };
      }
      if (this.analysis == 3) {
        // let tem1 = this.formData.temperature1 - this.formData.baseTem2;
        // let tem2 = (this.formData.temperature2 - this.formData.baseTem2) * this.formData.coefficient2;
        data = {
          projectId:this.projectId,
          type: this.formData.type,
          // temp: tem1 + "," + tem2
          temp: this.formData.t1 + "," + this.formData.t2
        };
      }
      if (this.analysis == 4) {
        if (this.formData.temGradient1 === "" || this.formData.temGradient2 === "") {
          return this.$message.error("请输入温度！");
        }
        data = {
          type: this.formData.type || "b",
          temp: this.formData.temGradient1 + "," + this.formData.temGradient2,
          projectId:this.projectId
        };
      }
      // data = {
      //     type: "b",
      //     temp: "30.1,18.2",
      //     projectId:"10034"
      //   };

      this.$refs["meshActive"].caluResultLoading()
      // this.$refs["meshActive"].caluResult({data:{}});
      // this.$refs["meshActive"].caluResult({
      //   data: {
      //     url: "http://114.115.162.96:9000/python-sim/20240201/f39b3ab0-c0ca-11ee-8212-fa163e7b4f2a.zip"
      //   }
      // });
      
      this.$axios.get(`${this.baseURL}simulation/temperature`,{ params:data } ).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("计算结果数据", res);
          // this.$bus.$emit("caluResult", res);
          this.flag = false;
          // 获取模型链接
          // this.$axios.get(`${this.baseURL}simulation/model/url/${this.projectId}`,{ params:data } ).then((res) => {
          //   res = res.data;
          //   if (res.errCode === "200") {
          //     console.log("模型链接", res);
          //   } else {
          //     this.$message.error(res.errMsg);
          //   }
          // })
          this.$refs["meshActive"].caluResult(res);
        } else {
          this.$message.error(res.errMsg);
        }
      }).catch(err=>{
        this.$refs["meshActive"].caluResultLoadingClose()
      })
    },
    changeTab(id) {
      this.active = id;
      this.$refs["meshActive"].active = id;
      this.$router.push({ path: "/simulation?active=" + id });
    },
    // 整体温度效应分析测点
    getTemAnalysis() {
      let params = {
        projectId: this.projectId
      };

      // var param = new URLSearchParams();

      // param.append("projectId", this.projectId); //时滞
      // let reg = {
      //   projectId: this.projectId
      // };

      // this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}simulation/target`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("整体温度效应测点数据", res);
          this.targetOptions = res.data || [];
          if (this.targetOptions.length) {
            this.formData.typeId = this.targetOptions[0].id;
            this.getTemAnalysisData("1");
          } else {
            this.formData.typeId = "";
          }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 根据测点查询最新数据
    getTemAnalysisData(temType) {
      let date5 = "",
        params;
      if (temType == 1) {
        if (!this.formData.analysisTime) {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 5 * 60 * 1000;

          date5 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          this.formData.analysisTime =moment(day2).format("YYYY-MM-DD HH:mm:ss");
          console.log("shijis", this.formData.analysisTime);
        }
        params = {
          targetId: this.formData.typeId,
          endTime: this.formData.analysisTime
        };
      }

      if (temType == 2) {
       
        if (!this.formData.analysisTime2) {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 5 * 60 * 1000;

          date5 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          this.formData.analysisTime1 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          console.log("shijis", this.formData.analysisTime1);
          
        } 
        params = {
          targetId: this.formData.typeId1,
          endTime: this.formData.analysisTime2 ||  this.formData.analysisTime1 
        };
        console.log('params===2',params)
      }
      if (temType == 3) {
        if (!this.formData.analysisTime2) {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 5 * 60 * 1000;

          date5 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          this.formData.analysisTime2 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          console.log("shijis", this.formData.analysisTime2);
        }
        params = {
          targetId: this.formData.typeId2,
          endTime:this.formData.analysisTime2
        };
        console.log('params====3',params)
      }

      if (temType == 4) {
        if (!this.formData.analysisTime2) {
          var day = new Date();
          var day1 = day.getTime(); //获得当前时间戳
          var day2 = day1 - 5 * 60 * 1000;

          date5 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          this.formData.analysisTime3 = moment(day2).format("YYYY-MM-DD HH:mm:ss");
          console.log("shijis", this.formData.analysisTime3);
        }
        params = {
          targetId: this.formData.typeId3,
          endTime: this.formData.analysisTime2 || this.formData.analysisTime3
        };
        console.log('params===4',params)
      }

      this.$axios.get(`${this.baseURL}simulation/target/last`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          // console.log("整体温度获取===温度", res);
          // var res = ""
          if (temType == "1") {
            this.formData.temperature = res.data;
            if (this.flag) {
              this.calculation();
            }
          }
          if (temType == "2") {
            this.formData.temperature1 = res.data;
            if (this.formData.temperature3) {
              this.formData.t1= ((this.formData.temperature1*1000000 -this.formData.temperature3*1000000)/1000000).toFixed(2)
            }
          }
          if (temType == "3") {
            this.formData.temperature2 = res.data;
            if (this.formData.temperature3) {
              this.formData.t2= ((this.formData.temperature2*1000000 -this.formData.temperature3*1000000)/1000000).toFixed(2)
            }
          }
          if (temType == "4") {
            this.formData.temperature3 = res.data;

            if (this.formData.temperature1) {
              this.formData.t1= ((this.formData.temperature1*1000000 -this.formData.temperature3*1000000)/1000000).toFixed(2)
            }
            if (this.formData.temperature2) {
              this.formData.t2= ((this.formData.temperature2*1000000 -this.formData.temperature3*1000000)/1000000).toFixed(2)
            }
          }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 分析测点1
    getTemAnalysis1() {
      let params = {
        projectId: this.projectId
      };

      this.$axios.get(`${this.baseURL}simulation/target/one`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("分析测点1", res);
          this.formData.temperature1=null
          this.targetOptions1 = res.data || [];
          // if (this.targetOptions1.length) {
          //   this.formData.typeId1 = this.targetOptions1[0].id;
          
          //   this.getTemAnalysisData("2");
          // } else {
          //   this.formData.typeId1 = "";
          // }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    // 分析测点2
    getTemAnalysis2() {
      let params = {
        projectId: this.projectId
      };

      this.$axios.get(`${this.baseURL}simulation/target/two`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.formData.temperature2=null
          console.log("分析测点2", res);
          this.targetOptions2 = res.data || [];
          // if (this.targetOptions2.length) {
          //   this.formData.typeId2 = this.targetOptions2[0].id;
          //   this.getTemAnalysisData("3");
          // } else {
          //   this.formData.typeId2 = "";
          // }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
  // 分析测点3
  getTemAnalysis3() {
      let params = {
        projectId: this.projectId
      };

      this.$axios.get(`${this.baseURL}simulation/target/three`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.formData.temperature3=null
          console.log("分析测点2", res);
          this.targetOptions3 = res.data || [];
          // if (this.targetOptions3.length) {
          //   this.formData.typeId3 = this.targetOptions3[0].id;
          //   this.getTemAnalysisData("4");
          // } else {
          //   this.formData.typeId3 = "";
          // }
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },

    addData() {
      console.log("打开窗口");
      this.$bus.$emit("modelAdd", "");
      this.open = 1;
    }
  }
};
</script>
<style scoped lang="scss">
.model-show {
}
.mar-l24 {
  margin-left: 24px;
}
.modelIndex {
  width: 100%;
  height: 100%;

  // border: 1px solid  red;
  // overflow-y: hidden;
  .ul1 {
    margin-bottom: 20px;

    li {
      // width: 140px;
      // padding: 0 20px;
      color: #ccdaff;
      // border-right:  1px solid #3A4A7B;
    }

    li:last-child {
      border-right: none;
    }
  }

  .tab1 {
    color: #fff !important;
  }

  // .fu{
  //     position: relative;
  //     width: 100%;
  //     top: -5px;
  //     img{
  //         width: 100%;
  //     }
  // }
  .heng {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    box-shadow: 0px -1px 4px 0px #ffffff;
    opacity: 0.32;
    border: 1px solid #1f61ff;
  }
}

.pad46 {
  padding-left: 46px;
  padding-right: 46px;
  box-sizing: border-box;
}

.mainBox {
  height: 100%;
}

.main {
  height: 100%;
  overflow: hidden;
}

.padL46 {
  padding-left: 46px;
  box-sizing: border-box;
}
.padR46 {
  padding-right: 46px;
  box-sizing: border-box;
}
.simulation {
  // padding-top: 34px;
  padding-left: 20px;
  padding-right: 20px;
  // padding-bottom: 10px;
  width: 100%;
  background: #04153f;
  // border-radius: 8px 8px 8px 8px;
  border: 1px solid #0C3380;
  -o-border-image: linear-gradient(119deg, rgb(17, 102, 255), rgba(0, 50, 175, 0.95)) 1 1;
  // border-image: linear-gradient(119deg, rgb(17, 102, 255), rgba(0, 50, 175, 0.95)) 1 1;
  position: relative;
  z-index: 0;
}
.main2 {
}

.ulTab {
  .fu {
    position: relative;
  }

  li {
    position: relative;
    cursor: pointer;
  }

  .tiao {
    position: absolute;
    top: 20px;
    width: 100%;
    height: 8px;
    background: url("../../../assets/images/report/juxing.png");
    background-size: 100% 100%;
    // background-position: 50px 0;
  }

  .shu {
    margin: 0 30px;
    width: 1px;
    height: 20px;
    background: #ccc;
  }
}
</style>
