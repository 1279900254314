<template>
  <div>
    <!-- <div id="loading_1" name="loading_1"  class=""></div> -->
    <meshForm @playChange="playChange" @inputPlay="inputPlay" @meshResultSelect="meshResultSelect" @getTargetOptions="getTargetOptions" @distortionChange="distortionChange" @inputEnter="inputEnter" @getTargetPosition="getTargetPosition" ref="meshform"></meshForm>
    <!-- <el-button @click="moduleChange">点击切换{{ i }}</el-button> -->
    <!-- <div class="box mB10 win">
      
      <div class="fs18 noFlex" style="width: 86px">仿真场</div>
      <el-select v-model="valueNum" placeholder="请选择" @change="getValue">
        <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div> -->
    <div class="tem_container_10_class box justify">
      <div class="tem_l radus24" v-show="meshTypeId == 3">
        <div v-if="true" class="tem_l_img">
          <img src="../../../assets/images/sansheng/jiemian_png@2x.png" alt="" />
          <el-table v-if="tableData.length" :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
            <el-table-column align="center" prop="id" label="节点" :show-overflow-tooltip="true"> </el-table-column>

            <el-table-column align="center" prop="score" label="正应力(Mpa)" :show-overflow-tooltip="true"> </el-table-column>
          </el-table>
        </div>
        <div class="noDataMain health-empty" v-show="!tableData.length">
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
      <div class="flex1 radus24 pos-rel">
        <div id="container_w_10" style="top: 0px; left: 0px; width: 100%; height: 100%; position: absolute" v-show="calcFlag"></div>
        <canvas id="lut_w_10" width="250" height="450" style="top: 0px; left: 10px; width: 250px; height: 450px; position: absolute"></canvas>
        <div id="gui_w_10" style="top: 35px; right: 5px; position: absolute"></div>
        <div id="helper_w_10" style="bottom: 50px; left: 10px; width: 128px; height: 128px; position: absolute"></div>
        <div class="info-res" v-show="nodeFlag != 3  && item.name">
          <span style="padding-right: 10px">{{ item.name }}</span>
          <span style="padding-right: 10px">{{ value }}</span>
          <span>{{ item.unit }}</span>
        </div>
        <draggable @start="onStart" @end="onEnd" @add="add2" animation="300"><div id="drag" class="reset-simulation-model" @click="resetIcon"></div></draggable>
      </div>
      <!-- <div class="load-text" v-if="active == 1" :class="{ 'text-pos': meshTypeId == 3 }">
        <div>
          <p>重庆三圣特大桥为主跨（80+3×150+80）m 预应力混凝土连续刚构桥，主梁采用单箱单室截面，</p>
          <p>顶板宽 12.0m，底板宽 6.5m，顶板悬臂长度 2.75m；箱梁跨中梁高 3.3m，根部梁高 9.3m，梁底变化曲线为 1.7 次抛物线。</p>
        </div>
        <div style="margin-top: 8px">
          <p>主梁采用挂篮悬臂对称浇筑施工，</p>
          <p>梁段 1~6#长度为 3.0m，7~12#长度为 3.5m，13~19#长度为 4.0m</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { showMesh } from "../jsm/threemain/showmesh"; // ./jsm/threemain/showmesh   tomcloudmap
// import { Guidat, ViewHelper, LUT_text, CMThree, appShowMesh, CMMidas } from "tomcloudmap";

// import { appShowMesh } from "../jsm2/three/appShowMesh";
// import { CMThree } from "../jsm2/cm/cmthree";
// import { CMMidas } from "../jsm2/cm/cmMidas";
// import { LUT_text } from "../jsm2/LUT/LUT_text";
// import { ViewHelper } from "../jsm2/helper/Viewport.ViewHelper";
// import { Guidat } from "../jsm2/helper/guidathelper";
import { appShowMesh,CMThree,CMMidas,LUT_text,ViewHelper,Guidat} from "tomcloudmap";

import meshForm from "./common/meshForm";
import { typeOptionsW } from "./common/mesh";
import { scien } from "./scientifec";
import draggable from "vuedraggable";

export default {
  name: "meshshow",

  components: { meshForm, draggable },
  data() {
    return {
      tableData: [],
      i: 1,
      valueNum: "U.U",
      // 'U.U', 'U.V', 'U.W', 'U.M', 'S.S11', 'S.S22', 'S.S33', 'S.S23', 'S.S13', 'S.S12'
      list: [],
      meshFlag: true,
      meshTypeId: "",
      active: "1",
      calcFlag: true,
      item: {},
      node: {},
      value: "",
      nodeFlag: "",
      drag: { start: 0, end: 0 },
      projectId: '',
      modelParams: null,
    };
  },
  computed: {},
  created() {
    // this.$bus.$on("caluResult", this.caluResult);
    this.projectId = this.$store.state.projectId || sessionStorage.getItem("projectId");
    this.getModelParams();
  },
  mounted() {
    // this.i = this.$route.query.i || 1;
    // console.log("mounted--i", this.i);
    // this.moduleVisbile(`./zip/${this.i}.zip`);
    this.active = this.$route.query.active || 1;
    console.log("active", this.active);
    document.getElementById("container_w_10").innerHTML = "";
    document.getElementById("helper_w_10").innerHTML = "";
    if (window.mainW) {
      window.mainW.clean();
      window.mainW = null;
    }
    this.$nextTick(() => {
      // this.moduleVisbile();
    });

    // this.moduleVisbileNew();

    // this.getModelParams();
  },
  watch: {},
  methods: {
    // 获取仿真模型参数
    getModelParams() {
      this.$axios.get(`${this.baseURL}simulation/bim/param/${this.projectId}`).then((res) => {
        this.modelParams = res.data.data;
      });
    },
    resetIcon() {
      console.log("click");
      window.mainW.setENVLast();
    },
    add2(e) {
      console.log("eeee-adddd", e);
    },
    onStart(e) {
      if (this.drag.start == 0 && this.drag.end == 0) {
        this.drag = { start: e.originalEvent.clientX, end: e.originalEvent.clientY };
      }
    },
    onEnd(e) {
      let start = e.originalEvent.clientX;
      let end = e.originalEvent.clientY;
      let oDiv = document.getElementById("drag");
      oDiv.style.right = this.drag.start - start + 20  + "px";
      oDiv.style.bottom = this.drag.end - end + 20  + "px";
    },
    playChange(play, frameFrequency) {
      if (play) {
        window.mainW.CM.play(frameFrequency);
      } else {
        let arr = window.mainW.CMs[0].getCurrentCMLevel();

        let len = arr[0].length;
        for (let i = 0; i < len; i++) {
          if (arr[0][i] == arr[1]) {
            // this.searchForm.currentCMLevel = i + 1;
            this.$refs.meshform.currentCMLevel = i + 1;
            break;
          }
        }

        window.mainW.CM.stop();
      }
      window.mainW.CM.show();
    },

    inputPlay(frameFrequency) {
      window.mainW.CM.stop();

      window.mainW.CM.play(frameFrequency);
      window.mainW.CM.show();
    },

    // 仿真节点id
    getTargetPosition(item1) {
      console.log("反正节点", item1);
      this.node = item1;
      mainW.CMs[0].cleanInfoPoints();

      mainW.CMs[0].cleanSelectPoint();
      console.log("反正节点111111", item1);
      mainW.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      console.log("反正节点22221", item1);
      mainW.CMs[0].showInfoPoints([item1.name], 0x888888, 3);
      let info = mainW.CMs[0].getOnePointAllPressure(item1.name);
      console.log("获取仿真节点返回数据", info);
      let reg = {};
      if (this.item.fid == 1 || this.item.fid == 3) {
        reg = info.STR;
      } else {
        reg = info.CM;
      }
      this.setNodeValue(item1, reg);
      // if (this.item.fid == 1) {
      //   if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
      //     this.value = res[this.item.text][0];
      //   } else {
      //     this.value = res[this.item.text][1];
      //   }
      // }
      // if (this.item.fid == 2) {
      //   let res1 = mainW.CMs[0].getOnePointAllCM(item1.name);
      //   console.log("获取仿真节点返回数据2222", res1);
      //   this.value = res1[this.item.id];
      // }

      // this.getStressDAta(item1.id, JSON.stringify(res));
    },
    setNodeValue(item1, res) {
      let val;
      if (this.item.fid == 1) {
        if (Math.abs(res[this.item.text][0]) > Math.abs(res[this.item.text][1])) {
          val = res[this.item.text][0];
        } else {
          val = res[this.item.text][1];
        }
      }
      if (this.item.fid == 2) {
        // res = mainW.CMs[0].getOnePointAllCM(this.item.id);
        val = res[this.item.id];
      }
      this.value = scien(Number(val).toExponential());

      // this.getStressDAta(item1.id, JSON.stringify(res));  应力数据去掉2023.11.13
    },
    // 变形系数
    inputEnter(distortionFactor) {
      mainW.CMs[0].setTransRate(distortionFactor);
      mainW.CMs[0].show();
    },
    // 是否变形
    distortionChange(check, distortionFactor) {
      //  参数 1、是否变形 2、boolean 变形系数
      mainW.CMs[0].setTransEnable(check);
      console.log("变形");
      if (check) {
        mainW.CMs[0].setTransRate(distortionFactor);
      }

      mainW.CMs[0].show();
    },
    caluResultLoading(){
      document.getElementById("container_w_10").innerHTML = "";
      document.getElementById("helper_w_10").innerHTML = "";
      if (window.mainW) {
        window.mainW.clean();
        window.mainW = null;
      }

      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });



    },
    caluResultLoadingClose(){
      this.loadingPage.close();
    },
    caluResult(res) {
      this.$refs["meshform"].formData.section = false;
      document.getElementById("container_w_10").innerHTML = "";
      document.getElementById("helper_w_10").innerHTML = "";
      if (window.mainW) {
        window.mainW.clean();
        window.mainW = null;
      }
      // let odiv = document.getElementById("container_w_10");
      // console.log("odiv.childNodes(0)", odiv.childNodes);
      // odiv.removeChild(odiv.childNodes[0]);
      // this.calcFlag = false;
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.calcFlag = true;
      //     this.moduleVisbile(res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
      //   }, 1000);
      // });
      this.$nextTick(() => {
        // this.calcFlag = true;
        // this.moduleVisbile(/*"./cm/sanshengbridge.zip"*/ res.data.url); //获取zip包传入  参数  *************XXXXXXXXXXXXXXXX
        // url = "", up, auto, scale, cameraPosition, type
        this.midasVisible(res.data, this.modelParams);
        // this.moduleVisbileNew(res.data.url);
      });
    },
    getTargetOptions(id) {
      console.log("qiehuan555", id, mainW);
      this.meshTypeId = id; // 切换仿真类型，界面变化
      if (this.meshTypeId == 3) {
        window.mainW.three.viewpoint_px = 506;
      } else {
        window.mainW.three.viewpoint_px = 62;
      }
      // window.mainW.CMs[0].show();
    },
    getMeshItem(id) {
      let n = typeOptionsW.length;
      let arr = typeOptionsW;
      // while(true){
      //   let arr1=arr
      //   for(let i=0;i<arr1.length;i++){
      //     if(arr1[i].id===id){
      //       return arr1[i]
      //     }else{
      //       if(arr1[i].data && arr1[i].data.length){
      //         arr=arr1
      //       }
      //     }

      //   }
      // }
    },
    // 云图交互-仿真结果
    meshResultSelect(id, typeid) {
      if (this.meshFlag) return;

      console.log("meshResultSelect-id", this.meshFlag, id, typeid, mainW.CMs[1], mainW.CMs[1].updateColors);
      this.nodeFlag = typeid;
      // let myid = typeOptions.filter((v) => v.id == id);
      if (typeid != 3) {
        let item = {};
        let n = typeOptionsW.length;
        for (let i = 0; i < n; i++) {
          if (typeOptionsW[i].id === typeid && typeOptionsW[i].data && typeOptionsW[i].data.length) {
            let k = typeOptionsW[i].data.length;
            for (let j = 0; j < k; j++) {
              console.log("云图交互", typeOptionsW[i].data[j].id);
              if (typeOptionsW[i].data[j].id === id) {
                this.item = typeOptionsW[i].data[j];
                this.item.fid = typeid;
                break;
              }
            }
          }
        }

        if (JSON.stringify(this.node) !== "{}") {
          this.getTargetPosition(this.node);
        }
      }

      setTimeout(() => {
        mainW.CMs[0].updateColors(id);

        window.mainW.CMs[0].show();
      }, 50);

      // switch (id) {
      //   case "2-1":
      //     mainW.CMs[0].updateColors("unoda0.u");
      //     break;
      //   case "2-2":
      //     mainW.CMs[0].updateColors("unoda0.v");
      //     break;
      //   case "2-3":
      //     mainW.CMs[0].updateColors("unoda0.w");
      //     break;
      //   case "2-4":
      //     mainW.CMs[0].updateColors("unoda1.anx");
      //     break;
      //   case "2-5":
      //     mainW.CMs[0].updateColors("unoda1.any");
      //     break;
      //   case "2-6":
      //     mainW.CMs[0].updateColors("unoda1.anz");
      //     break;
      // }
    },

    // 获取应力数据
    getStressDAta(pointsId, value) {
      let params = {
        pointsId, // 仿真节点id
        value // 应力
      };
      console.log("应力数据参数", params);
      // this.$axios.get(`${this.baseURL}data_management/data_type/group`, { params }).then((res) => {
      this.$axios.get(`${this.baseURL}simulation/calculate`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          console.log("获取应力5个点数据", res);

          let arr = [];
          if (res.data.length) {
            for (let i = 0; i < res.data.length; i++) {
              arr.push({ id: i + 1, score: res.data[i] });
            }
            this.tableData = arr;
          } else {
            this.tableData = [];
          }
        } else {
          // this.$message.error(res.errMsg);
          console.log("这里为什么报错呢", res);
        }
      });
    },

    getValue() {
      console.log("云图", this.valueNum);
      this.main.CM.show(this.valueNum);
      this.main.render();
    },
    getPointInfoAttr(id, info) {
      // mainT.CMs[0].cleanSelectPoint();
      mainW.CMs[0].cleanInfoPoints();
      this.$refs["meshform"].formData.positionGroupId = "";
      this.$refs["meshform"].formData.positionId = "";
      this.$refs["meshform"].pavement = "";
      console.log("模型传出值id", id, "info", info, "item", this.item);
      this.tableData = [];

      mainW.CMs[0].cleanInfoPoints();
      mainW.CMs[0].cleanSelectPoint();
      mainW.CMs[0].showInfoPoints([id], 0x888888, 3);

      let reg = {};
      if (this.item.fid == 1 || this.item.fid == 3) {
        reg = info.STR;
      } else {
        reg = info.CM;
      }
      this.setNodeValue({ id: id }, reg);
    },
    async midasVisible(res, modelParams) {
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在读取...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
        // target: document.querySelector(".tuBg")   //loading需要覆盖的DOM节点，默认为body
      });

      let that = this;
      let thisOfVUEentry = this;
      // clean DIV children ,on VDOM reload
      let cleanDIV = function (id) {
        let container = document.getElementById(id);
        container.innerHTML = "";
        // container.childNodes.forEach(function (item) {
        //     item.remove();
        // });
      };

      cleanDIV("container_w_10");
      cleanDIV("lut_w_10");
      cleanDIV("helper_w_10");
      cleanDIV("gui_w_10");

      // clean webGL canvas  ,on VDOM reload
      let cleanWebGL = function (object) {
        object.clean();
        object = {};
      };
      // log router
      let logRouter = function (msg) {
        console.log("Log Router :", msg);
        thisOfVUEentry.loadingPage.close();
      };

      let meshCM;
      let output = function (x, y, id, info) {
        console.log(x, y, id, info);
        thisOfVUEentry.getPointInfoAttr(id, info);
      };
      ///////////////////////////////////////////////////////////////////////////////////
      // value of init webGL
      let value = {
        DIV: "container_w_10",
        containerType: "div", //div or object
        ctl: "orbit", // orbit or traceball
        log: logRouter, //all log
        setting: {
          cameraAuto: Boolean(modelParams.auto), // ture or false ,if the value is true or undefined ,below settings will be disable  auto
          cameraPosition: modelParams.cameraPosition, //[10, 0, 0],//x,y,z  cameraPosition
          cameraMartix: false, //false or 4x4 martix ,if there has a array of matrix ,position will be igione
          cameraLookat: [], //x,y,z
          ctlTarget: [51, -13, -6], //x,y,z
          ////////////////////////////////////
          realTimeRender: true, //
          up: modelParams.up,  // up
          scale: Number(modelParams.scale), // scale
          type: 'bridge' // type
        },

        fun: {
          // beforeInit,init,afterinit of webGL's
          afterInit: function (that) {},
          init: function (that) {},
          onMouseClick: function (mainGL, raycaster, xy) {
            // console.log(raycaster, xy);
            if (mainGL.octree) {
              let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
              // mainGL.log("point id :", index);
              if (index) output(xy.eventxy.x, xy.eventxy.y, index, meshCM.getOnePointAllPressure(index));
            }
          }
          // onMouseMove: function (mainGL, raycaster, xy) {
          //     console.log(raycaster, xy);
          //     if (mainGL.octree) {
          //         let index = mainGL.octree.pickup(raycaster.ray.origin, raycaster.ray.direction, raycaster);
          //         mainGL.log("point id :", index);
          //     }
          // },
        },
        debug: true
      };
      console.log('valuevaluevalue', value)

      // init webGL
      this.mainWebGL = new appShowMesh(value);
      ///////////////////////////////////////////////////////////////////////////////////
      //init LUT
      // must be init before CM init
      let inputValueLUT = {
        DIV: "lut_w_10",
        containerType: "div", //div or object
        kind: "curve", // curve ,default
        fontColor: { r: 1, g: 1.0, b: 1 },
        parent: this.mainWebGL
      };
      this.mainWebGL.LUT = new LUT_text(inputValueLUT);

      ///////////////////////////////////////////////////////////////////////////////////
      //init viewHelper
      let inputValueViewHelper = {
        DIV: "helper_w_10",
        containerType: "div", //div or object
        perpCamera: this.mainWebGL.perpCamera,
        parent: this.mainWebGL,
        up: "Y"
      };

      this.mainWebGL.viewHelper = new ViewHelper(inputValueViewHelper);

      ///////////////////////////////////////////////////////////////////////////////////
      // value of CM mesh
      let meshMidas = {};
      let inputForCM = {
        parent: this.mainWebGL,
        file: res.url || "./cm/sanshengbridge.zip",
        check: 2,
        cache: false,
        // extra:{
        //     midas:{
        //         file:"./str/625.mct",
        //         fun:{
        //             replaceNode:function(){},
        //             replaceElement:function(){},
        //         }
        //     },
        // },
        // rotate:{x:-90},//{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            meshMidas.info.data.res = CM.info.data.res;
            meshMidas.info.data.str = CM.info.data.str;
            ///////////////////////////////////////////////////////////////////////////////////
            //demo : GUI init
            let list1 = CM.info.getSiteListOfStr();
            let list = CM.getNameListOfCM().concat();
            if (list1) list = list.concat(list1);
            // let inputValueGUI = {
            //   DIV: "gui_w_10",
            //   containerType: "div", //div or object
            //   CM: CM,
            //   parent: CM.parent,
            //   listCM: list
            //   // CM.getNameListOfCM(),
            // };
            // CM.parent.gui = new Guidat(inputValueGUI);
            // CM.Draw.drawShellPointsMerge("all");
            // CM.initPickUp(CM, 3, 0x888888);
            CM.initPickUp(CM, .5, 0xff8800);
            CM.Draw.drawShellPointsMerge("all", 0xffffff, .1);
            // CM.initPickUp(CM, this.radiusOfPointOfPickup, 0xff8800);
            // CM.Draw.drawShellPointsMerge("all", 0xffffff, this.radiusOfPoint);

            window.mainW.three.viewpoint_py = 387;
            window.mainW.three.viewpoint_px = 62;

            console.log("云图加载完成", thisOfVUEentry.$refs["meshform"]);
            thisOfVUEentry.meshFlag = false;
            thisOfVUEentry.$nextTick(() => {
              console.log("云图加载完成=====", thisOfVUEentry, thisOfVUEentry.$refs["meshform"]);
              if (window.mainW) {
                window.mainW.setENVLast();
              }
              thisOfVUEentry.$refs["meshform"].formData.checked = false;
              thisOfVUEentry.$refs["meshform"].formData.section = true;
              thisOfVUEentry.$refs["meshform"].getTargetOptions("1", false, window.mainW);
              thisOfVUEentry.$refs["meshform"].getSection();
            });
          }
        },
        CM: {
          // type:"gid/inp",
          region: true //true/false
        },

        center: true, //true,false
        process: {
          add: {},
          edit: [
            {
              type: "point",
              nodes: [],
              value: [{}]
            },
            {
              type: "generate",
              nodes: [],
              value: [{}]
            }
          ],
          del: {}
        }
      };

      //init Mesh
      meshCM = new CMThree(inputForCM);
      this.mainWebGL.CMs.push(meshCM);
      let nodeMap = res['nod_url'];
      let elementsMap = res['elem_url'];
      let nodeJSON_text = await this.getUrl(nodeMap);
      let elemJSON_text = await this.getUrl(elementsMap);
      let node = this.formatMap(nodeJSON_text);
      let elem = this.formatMap(elemJSON_text);
      ///////////////////////////////////////////////////////////////////////////////////
      // value of midas mesh
      let elemJSON = elem.outJson;
      let nodeJSON = node.outJson;
      let nodeJSONOld = node.outOldJson;
      let inputForMidas = {
        parent: this.mainWebGL,
        file: "./cm/xmh.mct",
        check: 2,
        visible: false,
        extra: {
          midas: {
            fun: {
              replaceNode: function (data) {
                console.log(data, "ssssssssss");
                let oldData = JSON.parse(JSON.stringify(data.nodes));
                console.log(oldData);
                data.nodes = [];
                for (let i in nodeJSON) {
                  data.nodes[i] = JSON.parse(JSON.stringify(oldData[nodeJSON[i]]));
                }
              },
              replaceElement: function (data) {
                console.log(data);
                let oldData = JSON.parse(JSON.stringify(data.elements));
                console.log(oldData);
                data.elements = [];
                for (let i in elemJSON) {
                  if (typeof oldData[elemJSON[i]] != "undefined" && oldData[elemJSON[i]]) {
                    data.elements[i] = JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                    if (i == 451) {
                      let agc = 1;
                    }
                    for (let j in data.elements[i].iNs) {
                      data.elements[i].iNs[j] = nodeJSONOld[data.elements[i].iNs[j]];
                    }
                  }
                  // JSON.parse(JSON.stringify(oldData[elemJSON[i]]));
                }
              }
            },
            //     data: {
            //         res: meshCM.info.data.res,
            //         str: meshCM.info.data.str,
            //     },
            resOBJ: meshCM
          }
        },
        // rotate:{x:-90},//{x:0,y:90,z:0}
        callbacks: {
          log: logRouter,
          afterDraw: function (CM) {
            // CM.info.data.res = meshCM.info.data.res;
            // CM.info.data.str = meshCM.info.data.str;
          }
        }
      };
      //init midas
      meshMidas = new CMMidas(inputForMidas);
      this.mainWebGL.CMs.push(meshMidas);
      ///////////////////////////////////////////////////////////////////////////////////
      //huag up to global ENV,test only
      window.mainW = this.mainWebGL;
    },
    async getUrl(url) {
        let xml = new XMLHttpRequest();
        xml.open("GET", url);
        xml.send();
        let text = await new Promise((resolve, reject) => {
            xml.onload = () => {
                console.log("OK", xml);
                if (xml.status == 200) {
                    resolve(xml.response);
                } else if (xml.status == 404) {
                    reject(new Error(xml.status));
                } else {
                    reject(new Error("error"));
                }
            };
        });
        return text;
    },
    formatMap(text) {
        let outJson = {};
        let outOldJson = {};

        let lines = text.split("\n");
        for (let perLine of lines) {
            if (perLine != "") {
                let perArray = perLine.split(/\s+/g);
                outJson[perArray[1]] = perArray[0];
                outOldJson[perArray[0]] = perArray[1];
            }
        }
        return { outJson, outOldJson };
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.win {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 150px !important;
}
::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
.tem_l_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
}
.tem_l_img img {
  width: 320px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}
#lut_10_2 {
  position: absolute;
  // z-index: 99;
  // top: 120px !important;
}
.pos-rel {
  position: relative;
}
.radus24 {
  // background: #102048;
  border-radius: 24px;
}

.tem_l {
  margin-left: 10px;
  width: 418px;
  height: 100%;
  margin-right: 16px;
}

.lut_10_class_2 {
  top: -7px;
  left: 26px;
  width: 300px;
  height: 300px;
  position: absolute;
}
::v-deep .el-input {
  height: 32px;
}
.gui_10_class {
  top: 35px;
  right: 5px;
  position: absolute;
}

.helper_10_class_2 {
  bottom: 50px;
  left: 26px;
  width: 128px;
  height: 128px;
  position: absolute;
}

#loading_1 {
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -30px;
  z-index: 3;
  display: block;
  width: 60px;
  height: 60px;
  background: url("../../../assets/images/gif.gif");
  background-size: 100% 100%;
}
.head-load-form {
  // z-index: 50;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.pad26 {
  padding-left: 26px;
  padding-right: 26px;
  box-sizing: border-box;
}
.tem_container_10_class {
  top: 99px;
  left: 20px;
  right: 0px;
  bottom: 20px;
  position: absolute;
  // background: #102048;
  // border-radius: 24px;
}
.container_10_class_2 {
  height: 100%;
  width: 100%;
}

.btnStyle {
  border: 1px solid #2667db;
  text-align: center;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
}

.active-bg {
  background-color: #2667db;
}

.pointer {
  cursor: pointer;
}

.el-form-custom .sim-position ::v-deep .el-input {
  width: 100% !important;
}

@media screen and (min-width: 1880px) {
  .el-form-custom .sim-position .el-input {
    width: 100% !important;
  }
}

.load-text {
  width: 810px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -405px;
  bottom: 50px;
}

.text-pos {
  margin-left: -45px;
}
</style>
